export default {
  "button": {
    "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestigen"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmaken"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslaan"])},
    "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerken"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijderen"])},
    "resetStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het logboek leegmaken?"])},
    "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer weergeven"])},
    "seeLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minder weergeven"])},
    "seeAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles weergeven"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleren"])},
    "see": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijken"])},
    "ignore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negeren"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluiten"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afval"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volgende"])},
    "leave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beëindigen"])}
  },
  "link_words": {
    "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dag"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Van"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tot"])}
  },
  "input": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A.u.b. dit veld invullen"])},
    "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit veld is verplicht"])},
    "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De invoer in dit veld is ongeldig"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In dit veld mogen alleen cijfers staan"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit adres is ongeldig"])},
    "length": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Moet ", _interpolate(_named("length")), " tekens lang zijn"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken..."])}
  },
  "errors": {
    "oups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oeps !"])},
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout"])},
    "401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegang geweigerd"])},
    "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagina niet gevonden"])},
    "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interne fout"])},
    "contactSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neem contact op met Support"])}
  },
  "alerts": {
    "connection": {
      "connected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["U bent ingelogd als ", _interpolate(_named("name"))])},
      "disconnected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U bent uitgelogd"])},
      "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw sessie is verlopen, log a.u.b. opnieuw in"])},
      "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinding niet mogelijk"])},
      "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foutieve inloggegevens"])},
      "registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw account is geregistreerd"])}
    },
    "form": {
      "incorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sommige informatie op dit formulier is niet correct of incompleet"])},
      "different": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord en wachtwoordbevestiging moeten identiek zijn"])},
      "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U dient de gebruiksvoorwaarden te accepteren om een account te kunnen aanmaken"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U moet een land selecteren"])},
      "industryField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je moet een activiteitssector selecteren"])},
      "invalid_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De ingevoerde tijd is ongeldig"])},
      "required_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U dient een dag te selecteren"])},
      "vigik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U dient een Vigik+ provincie te selecteren"])},
      "residence_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U dient een type residentie te selecteren"])}
    },
    "version": {
      "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik hier om de update te laden"])},
      "new_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update van website beschikbaar"])}
    },
    "notifications": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["U heeft ", _interpolate(_named("count")), " notificatie"]), _normalize(["U heeft ", _interpolate(_named("count")), " notificaties"])])},
    "globalNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe berichten"])},
    "no_mobile_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze pagina is nog niet geoptimaliseerd voor mobiele weergave. We werken er momenteel aan."])},
    "access": {
      "opendoor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De openingsopdracht is verzonden."])}
    },
    "residence": {
      "rgpd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw AVG-voorkeuren zijn opgeslagen"])}
    },
    "errors": {
      "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit ID wordt al gebruikt!"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze badge is al in dit trappenhuis geregistreerd!"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze toetsenbordcode bestaat al voor deze toegang!"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze telefooncode bestaat al voor deze toegang!"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze combinatie van naam en ID bestaat al voor dit appartement!"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze sneltoets is al in gebruik!"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze feestdag bestaat al!"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze badge wordt al als groepssleutel gebruikt!"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U heeft de quota (aantal gebruikers...) van uw GSM-contract overschreden!"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit GSM-contractnummer werd niet gevonden!"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw centrale unit wordt tariefredenen niet bijgewerkt!"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U bent niet gerechtigd om deze dienst aan te passen!"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze toegang wordt gebruikt door een of meerdere autorisaties of is met een centrale unit verbonden, u kunt deze niet verwijderen!"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze autorisatie wordt gebruikt door een of meerdere badges, u kunt deze niet verwijderen!"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze functie is momenteel alleen beschikbaar in Microsoft Internet Explorer!"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak a.u.b. appartementen aan, om badges te kunnen programmeren."])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stel a.u.b. de centrale unit van tevoren in!"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze centrale unit is al in gebruik!"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De naam van dit appartement wordt al gebruikt!"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De naam van dit trappenhuis wordt al gebruikt!"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De naam van dit appartement wordt al gebruikt voor dit trappenhuis!"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De naam van deze autorisatie wordt al gebruikt!"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De naam van deze toegang wordt al gebruikt!"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De naam van deze groepssleutel wordt al gebruikt!"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt niet meer dan 11 toetsenbordcodes per deur aanmaken!"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt niet meer dan 10 telefooncodes per deur aanmaken!"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt niet meer dan 1 toetsenbordcode voor deze deur aanmaken!"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt niet meer dan 16 Read/Write-toegangen op een autorisatie aanmaken!"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt voor deze groepssleutel niet meer dan 6 read/write-residenties programmeren!"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt voor deze groepssleutel niet meer dan 6 read/write-trappenhuizen programmeren!"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt voor deze groepssleutel niet meer dan 6 read/write-toegangen programmeren!"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze centrale unit is al in gebruik!"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controleer het serienummer of contractnummer dat u heeft ingevuld."])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enkele GSM-modules konden niet worden bijgewerkt omdat het maximale aantal gebruikers is overschreden!"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enkele centrale units zijn van het type read/write en moeten eerst in bedrijf worden genomen!"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze functie vereist de installatie van de USB-programmeur (alleen beschikbaar onder Internet Explorer)!"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze toegang wordt gebruikt door een autorisatie - u kunt deze niet wijzigen!"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze toegang wordt gebruikt door toetsenbordcodes - u kunt deze niet wijzigen!"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt alleen intercoms met pijltjestoetsen aan deze toegang toewijzen!"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt geen intercom met pijltjestoetsen aan deze toegang toewijzen!"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U dient deze toegang eerst aan een centrale unit toe te wijzen!"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit materiaal is alleen in Frankrijk beschikbaar!"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt niet meer dan 18 read/write-residenties aan deze groepssleutel toewijzen!"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt niet meer dan 18 read/write-trappenhuizen aan deze groepssleutel toewijzen!"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt niet meer dan 18 read/write-toegangen aan deze groepssleutel toewijzen!"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt geen read/write-residenties aan deze groepssleutel toewijzen!"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt geen read/write-trappenhuizen aan deze groepssleutel toewijzen!"])},
      "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt geen read/write-toegangen aan deze groepssleutel toewijzen!"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt niet meer dan 5 read/write-residenties aan deze groepssleutel toewijzen!"])},
      "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt niet meer dan 5 read/write-trappenhuizen aan deze groepssleutel toewijzen!"])},
      "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt niet meer dan 5 read/write-toegangen aan deze groepssleutel toewijzen!"])},
      "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt niet meer dan 5 read/write-toegangen aan een autorisatie toewijzen!"])},
      "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U dient deze centrale unit eerst met een toegang te verbinden!"])},
      "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het serienummer van de centrale unit is ongeldig!"])},
      "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt niet meer dan 10 LIGHT toegangen aan een autorisatie toewijzen!"])},
      "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U dient badges of afstandsbedieningen te gebruiken om deze toegangen te openen!"])},
      "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt niet meer dan 10 LIGHT toegangen aan deze badge toewijzen!"])},
      "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In dit trappenhuis zijn geen verdere appartementen beschikbaar!"])},
      "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importering gegevens succesvol voltooid"])},
      "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt de extensiekaart niet verwijderen! Er is hardware verbonden met connector 3 of 4"])},
      "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw USB-programmeur ondersteunt deze functie niet!"])},
      "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een fout opgetreden bij het laden van de backup, probeert u het a.u.b. later opnieuw!"])},
      "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De ingevoerde kaart is geen backup-geheugenkaart."])},
      "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controleer of het backup-geheugen correct is ingevoerd!"])},
      "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een fout opgetreden bij het laden van het backup-geheugen, probeert u het a.u.b. later opnieuw!"])},
      "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U heeft één backup-geheugenkaart op de interne connector en één op de smartcard-lezer aangesloten..."])},
      "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze mobile key is al in dit trappenhuis geprogrammeerd!"])},
      "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw USB-programmeur is niet compatibel met deze functie!"])},
      "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een fout opgetreden bij het laden van het geheugen, probeert u het a.u.b. later opnieuw!"])},
      "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De ingevoerde kaart is geen geheugenkaart!"])},
      "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controleer of de geheugenkaart correct is ingevoerd!"])},
      "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een fout opgetreden bij het laden van het geheugen, probeert u het a.u.b. later opnieuw!"])},
      "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De ingevoerde kaart is niet de geheugenkaart voor deze centrale unit!"])},
      "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U heeft het maximale aantal toetsenbordcodes voor deze toegang bereikt!"])},
      "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze toegang kan niet worden verbonden met dit type connector!"])},
      "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U heeft het maximale aantal sms-verzendpogingen bereikt!"])},
      "80": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een fout opgetreden bij het verzenden van de SMS, probeert u het a.u.b. later opnieuw!"])},
      "81": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U heeft het maximale aantal toetsenbordcodes voor deze centrale unit bereikt!"])},
      "82": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze toegang kan uitsluitend met connector no. 1 verbonden worden!"])},
      "83": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze toegang kan niet worden verbonden met dit type centrale unit of met dit connectornummer!"])},
      "84": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze codes zijn onbekend!"])},
      "85": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze productcode is al geactiveerd!"])},
      "86": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U heeft het maximale aantal badges voor dit appartement bereikt!"])},
      "87": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U heeft het maximale aantal afstandsbedieningen voor dit appartement bereik!"])},
      "88": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alleen letters (a-z)(A-Z), cijfers (0-9) en tekens (.,-,_) zijn toegestaan, spaties zijn niet toegestaan."])},
      "89": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt geen fiches meer aanmaken in dit appartement!"])},
      "90": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer tenminste één residentie!"])},
      "91": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer tenminste één trappenhuis!"])},
      "92": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer tenminste één deur!"])},
      "93": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer tenminste één toegang!"])},
      "94": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer tenminste één kantoor!"])},
      "95": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze badge bestaat niet"])},
      "96": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U heeft het maximale aantal groepssleutels bereikt!"])},
      "97": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De gebruiker heeft al een sleutel van dit type."])},
      "100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze invoer is verwijderd!"])},
      "101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U heeft het maximale aantal toegestane feestdagen bereikt!"])},
      "102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze use case wordt gebruikt op andere toegangen en kan niet worden verwijderd!"])},
      "103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze week wordt gebruikt in een tijdsbereik!"])},
      "104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze gebruiker bestaat niet!"])},
      "105": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet toegankelijk!"])},
      "106": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze gebruikersnaam is al in gebruik!"])},
      "107": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De naam van dit gebouw is al in gebruik!"])},
      "108": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gegevens om te verwerken!"])},
      "109": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze badge is niet aanwezig!"])},
      "110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout bij het starten van de update, probeert u het a.u.b. later opnieuw."])},
      "111": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze badge is ongeldig of al in gebruik!"])},
      "112": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze barcode bestaat niet!"])},
      "113": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze barcode is al in gebruik!"])},
      "114": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De service om een account aan te maken is niet toegankelijk, probeert u het a.u.b. later opnieuw"])},
      "115": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze account kan niet worden verplaatst."])},
      "116": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze gebruiker heeft al een sleutel van dit type."])},
      "117": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U heeft nog geen subaccounts."])},
      "120": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U heeft één geheugenkaart op de interne connector en één op de smartcard-lezer aangesloten..."])},
      "200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De naam van dit tijdsbereik is al in gebruik!"])},
      "201": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit tijdsbereik is in gebruik en kan niet worden verwijderd!"])},
      "202": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er zijn gegevens die met uw zoektermen overeenkomen..."])},
      "203": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het ingevoerde wachtwoord is ongeldig..."])},
      "204": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies a.u.b. tenminste één ontvanger!"])},
      "301": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let op! U heeft uw contract overschreden. U kunt de centrale unit niet bijwerken ..."])},
      "302": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let op: het veld telefoonnummer is leeg, u kunt uw centrale niet updaten..."])},
      "303": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let op! U heeft het maximaal toegestane aantal internationale telefoonnummers overschreden..."])},
      "304": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let op! Uw contract is niet compatibel met deze materiaalconfiguratie."])},
      "305": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let op: uw pakket is niet geautoriseerd voor video-oproepen. Breidt u uw contract a.u.b. uit met de video-optie of schakel de videofunctie uit bij de betreffende bewoners."])},
      "306": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt dit type Intrabox Eco Kit niet in een basisresidentie registreren."])},
      "700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt niet meer dan tien badges van dit type programmeren."])},
      "800": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit GSM-contract is al in gebruik!"])},
      "801": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een fout opgetreden. Neemt u a.u.b. contact op met Support."])},
      "802": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer a.u.b. het serienummer van de GSM-module of het telefoonnummer in."])},
      "803": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is geen materiaal aan dit contract toegewezen."])},
      "804": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit type contract is niet compatibel met dit type residentie."])},
      "805": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om deze module te registreren, kiest u eerst uw materiaal (intercom, badgelezer...) en dan 'centrale configureren'"])},
      "806": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let op: uw contract is opgeschort, neemt u alstublieft contact op met INTRATONE (+31 (0) 20 788 3401)"])},
      "1001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een update van het informatiescherm is bezig... Even geduld a.u.b...."])},
      "1002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De tekst is te lang, maak deze a.u.b. korter..."])},
      "1003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U dient tenminste de kolom 'Naam appartement' te selecteren!"])},
      "1004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een kolom geeft de fichenaam aan, maar het primaire telefoonnummer ontbreekt!"])},
      "1005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een fout heeft de importering onderbroken!"])},
      "1006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een secundair telefoonnummer is ongeldig!"])},
      "1007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een primair telefoonnummer is ongeldig!"])},
      "1008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een gebruikersnaam is ongeldig!"])},
      "1009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een naam is ongeldig!"])},
      "1010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een appartementnaam is ongeldig!"])},
      "1011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een serienummer is ongeldig!"])},
      "1012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ontbreekt een primair telefoonnummer!"])},
      "1013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een gebruikersnaam ingevoerd zonder een fichenaam!"])},
      "1014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een secundair telefoonnummer ingevoerd zonder een fichenaam!"])},
      "1015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een sneltoets ingevoerd zonder een fichenaam!"])},
      "1016": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een weergavegroep ingevoerd zonder een fichenaam!"])},
      "1017": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een primair telefoonnummer ingevoerd zonder een fichenaam!"])},
      "1018": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit type bestand wordt niet ondersteund!"])},
      "1019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geavanceerd beheer is niet mogelijk, de gebruiker heeft algemene toegang tot de residenties!"])},
      "1020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is al een sneltoets in gebruik!"])},
      "1021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De afbeelding is te groot!"])},
      "1022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U dient een kolom voor de trappenhuizen een een kolom voor de namen van de appartementen te selecteren!"])},
      "1023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze afbeelding is in gebruik, verwijderen is niet mogelijk!"])},
      "1024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit type bestand is niet compatibel!"])},
      "1200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout bij het verzenden van de gegevens"])},
      "1201": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De weergavetijd moet een positieve waarde hebben"])},
      "1202": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De begindatum is ongeldig"])},
      "1203": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De einddatum is ongeldig"])},
      "1204": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De begindatum moet eerder zijn dan einddatum"])},
      "1205": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout bij het opslaan"])},
      "1206": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies a.u.b. een fiche!"])},
      "1207": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit bestand kon niet worden geïmporteerd!"])},
      "1300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definieer a.u.b. de betreffende toegang"])},
      "1400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijdering van bewoners voltooid..."])},
      "1401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijdering van badges en afstandsbedieningen is voltooid..."])},
      "1500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sommige informatie op het formulier is ongeldig"])},
      "1600": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informatie over dit appartement kan niet gevonden worden"])},
      "1601": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoonnummer heeft niet het juist format"])},
      "1602": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout bij het ophalen van het telefoonnummer"])},
      "1603": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De badge kan niet worden toegevoegd"])},
      "1604": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout bij het ophalen van de informatie van de badge"])},
      "1605": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet genoeg badges van een bepaald type"])},
      "1606": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te veel badges van een bepaald type"])},
      "1607": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmeerinformatie kan niet worden opgehaald"])},
      "1608": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout bij het aanmaken van de fiche"])},
      "1609": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout bij het bijwerken van de fiche"])},
      "1610": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De fiche is al aan een telefoonnummer gekoppeld"])},
      "1611": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout: meerdere fiches in het appartement"])},
      "1612": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout bij het ophalen van de informatie van de fiches"])},
      "1613": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout bij het aanmaken van een badge"])},
      "1615": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let op! Deze handeling vereist dat u de Connect.it-antenne verwijdert die met uw module verbonden is."])},
      "1700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout: Uw account is al een beheerdersaccount"])},
      "1701": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan niet in een beheerdersaccount worden gewijzigd"])},
      "1702": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan niet in een beheerdersaccount worden gewijzigd. Uw e-mailadres is niet ingevuld."])},
      "1800": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout: De naam van de centrale unit dient te worden ingevuld"])},
      "1801": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout: configuratieprobleem"])},
      "1802": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout: U dient de naam van de toegang in te voeren"])},
      "1803": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout: Uw contract staat het toevoegen van dit materiaal niet toe"])},
      "1804": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout: Het materiaal is al geregistreerd"])},
      "1805": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout: het contract kon niet geïdentificeerd worden, voltooit u a.u.b de gegevens"])},
      "2016": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bij het aanmaken van een account is een fout opgetreden."])},
      "2019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een fout opgetreden. Probeert u het later opnieuw."])},
      "2020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Functie door uw beheerder geblokkeerd"])},
      "API_RESTRICTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U heeft niet de vereiste bevoegdheid voor deze actie."])},
      "PARAMS_INVALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een fout opgetreden, probeer het later nog eens."])},
      "PARAMS_MISSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een fout opgetreden, probeer het later nog eens."])},
      "ACCES_TYPEEXIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout: Het type toegang is al geprogrammeerd"])},
      "ACCES_TYPEFAILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred when requesting the creation of an access type."])},
      "POLICY_RESTRICTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U heeft niet de vereiste bevoegdheid voor deze actie."])},
      "WD_0_TIME_END_HIGHER_TIME_START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Monday slot with a later end time than the start time"])},
      "WD_1_TIME_END_HIGHER_TIME_START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Tuesday slot with a later end time than the start time"])},
      "WD_2_TIME_END_HIGHER_TIME_START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Wednesday slot with a later end time than the start time"])},
      "WD_3_TIME_END_HIGHER_TIME_START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Thursday slot with a later end time than the start time"])},
      "WD_4_TIME_END_HIGHER_TIME_START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Friday slot with an end time later than the start time"])},
      "WD_5_TIME_END_HIGHER_TIME_START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Saturday slot with an end time later than the start time"])},
      "WD_6_TIME_END_HIGHER_TIME_START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Sunday slot with an end time later than the start time"])},
      "WD_0_TIME_OVERLAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An overlap between 2 time slots has been detected for Monday"])},
      "WD_1_TIME_OVERLAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An overlap between 2 time slots has been detected for Tuesday"])},
      "WD_2_TIME_OVERLAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A 2-slot overlap has been detected for Wednesday"])},
      "WD_3_TIME_OVERLAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A 2-slot overlap has been detected for Thursday"])},
      "WD_4_TIME_OVERLAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An overlap between 2 time slots has been detected for Friday"])},
      "WD_5_TIME_OVERLAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An overlap between 2 time slots has been detected for Saturday"])},
      "WD_6_TIME_OVERLAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An overlap between 2 time slots has been detected for Sunday"])},
      "WD_0_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een fout opgetreden, probeer het later nog eens."])},
      "WD_1_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een fout opgetreden, probeer het later nog eens."])},
      "WD_2_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een fout opgetreden, probeer het later nog eens."])},
      "WD_3_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een fout opgetreden, probeer het later nog eens."])},
      "WD_4_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een fout opgetreden, probeer het later nog eens."])},
      "WD_5_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een fout opgetreden, probeer het later nog eens."])},
      "WD_6_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een fout opgetreden, probeer het later nog eens."])},
      "2021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een van de ingevulde velden is onjuist. Controleer dit a.u.b."])}
    }
  },
  "language": {
    "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frans"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engels"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een taal"])}
  },
  "global": {
    "entities": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startpagina"])},
      "residence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residentie"])},
      "residenceType": {
        "basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basis wooncomplex"])},
        "simplified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eenvoudig wooncomplex"])},
        "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volledig wooncomplex"])}
      },
      "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebouw"])},
      "stairwell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trappenhuis"])},
      "apartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appartement"])},
      "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegang"])},
      "resident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewonersfiche"])}
    },
    "status": {
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bijwerken"])},
      "updateAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles bijwerken"])},
      "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bijgewerkt"])},
      "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wordt bijgewerkt..."])},
      "needUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update nodig"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update mislukt"])},
      "commissioning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In gebruik genomen"])},
      "optionPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass-optie"])},
      "updateResidence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veranderingen van residentie toegepast"])},
      "inService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In gebruik"])},
      "notInService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buiten dienst"])},
      "error2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout"])}
    },
    "modal": {
      "deviceNotCompatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'appareil que vous utilisez pour programmer vos accessoires n'est pas compatible avec les programmateurs USB. Veuillez utiliser un ordinateur."])},
      "delete_cylinder?_is_irreversible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u zeker dat u deze cylinder wilt verwijderen? Deze actie kan niet ongedaan worden gemaakt."])},
      "window_open_site_in_host_please_close_it": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Je hebt al een venster geopend met een programmer op de ", _interpolate(_named("name")), " site. Sluit dit venster om je programmeurs op deze pagina te kunnen gebruiken."])},
      "device_using_program_accessories_not_compatible_USB_programmers_use_computer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het apparaat dat u gebruikt om uw accessoires te programmeren is niet compatibel met USB-controllers. Gebruik a.u.b. een PC."])},
      "you_need_update_programmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U moet de versie van uw programmeur updaten om accessoires toe te voegen of bij te werken."])},
      "edit-time-range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijdslots wijzigen"])},
      "create-time-range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak een tijdslot aan"])},
      "copie-day-time-range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De tijden kopiëren"])},
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let Op !"])},
      "rgpd": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["AVG-naleving van de gebeurtenissen van de residentie : ", _interpolate(_named("name"))])},
      "valid": {
        "week-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De nieuwe tijdsbereiken valideren"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u zeker dat u de wijzigingen wilt annuleren?"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U dient de wijzigingen te valideren"])},
        "raz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
        "access-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het nieuwe type toegang valideren"])},
        "delete-access-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to remove this type of access ?"])},
        "delete-residence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residentie verwijderen"])},
        "politicsUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vigik Modification Pending"])},
        "politicsUpdateText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that any customizations made previously will be removed if you validate this update. Would you like to use it ?"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terugzetten naar fabrieksinstellingen"])}
      },
      "vigik": {
        "not-enough-time-slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U heeft het maximale aantal tijdsbereiken per dag bereikt. Verwijder enkele tijdsbereiken om nieuwe aan te kunnen maken."])}
      },
      "providersStatus": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Samenvatting van de wijzigingen (", _interpolate(_named("count")), ")"])},
        "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activiteiten"])},
        "authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegestaan"])},
        "noAuthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet toegestaan"])}
      },
      "doubleAuth": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FA (2-factor authenticatie)"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een eenmalige validatiecode is naar het volgende e-mailadres verzonden:"])},
        "validationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validatiecode"])},
        "error": {
          "attempt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["De validatiecode is niet correct. U heeft nog ", _interpolate(_named("count")), " pogingen"]), _normalize(["De validatiecode is niet correct. U heeft nog ", _interpolate(_named("count")), " pogingen"])])},
          "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begin de procedure opnieuw. U wordt naar de inlogpagina gestuurd over 10 seconden."])},
          "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De code is niet langer geldig."])}
        }
      }
    },
    "array": {
      "emptyList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lege lijst"])},
      "noSearchData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is geen data die met uw zoekopdracht overeenkomt"])}
    }
  },
  "weekType": {
    "weekday": {
      "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maandag"])},
      "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dinsdag"])},
      "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Woensdag"])},
      "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donderdag"])},
      "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrijdag"])},
      "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaterdag"])},
      "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zondag"])}
    },
    "copie-choice": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Op welke dagen wilt u het ", _interpolate(_named("day")), " schema kopiëren ?"])},
    "max-different-day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je hebt het maximum aantal verschillende dagen in de kalender bereikt. Wil je het rooster van een andere dag opnieuw gebruiken?"])}
  },
  "vigik": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vigik + configuratie"])},
    "form": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam van het toegangstype"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschrijving"])},
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopieer de operatie"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wilt u de creatie van een nieuw toegangstype annuleren?"])},
      "create-question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wilt u dit nieuwe type toegang creëren?"])},
      "openingPriority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening priority"])},
      "obligatoryFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required field"])},
      "minimalTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het tijdsbereik dient langer te zijn dan 15 minuten"])},
      "opened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
      "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])}
    },
    "modale": {
      "copySchedule": {
        "copyTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiëren"])},
        "onNextDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Op de volgende dagen"])}
      },
      "createSchedule": {
        "limitAtFiveSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt niet meer dan vijf verschillende tijdsbereiken per dag aanmaken."])},
        "existingSchedules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is already a slot at these times"])}
      }
    },
    "global": {
      "vigik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vigik"])},
      "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activiteiten"])},
      "customProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customized service providers"])},
      "bannedProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verboden providers"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "whitelisted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Gepersonaliseerd"]), _normalize([_interpolate(_named("count")), " gepersonaliseerd"]), _normalize([_interpolate(_named("count")), " gepersonaliseerd"])])},
      "blacklisted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Verboden"]), _normalize([_interpolate(_named("count")), " verboden"]), _normalize([_interpolate(_named("count")), " verboden"])])},
      "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standaard"])},
      "activitiesAllows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorised activity"])},
      "activitiesBanned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity not authorised"])},
      "parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuratie"])},
      "allAutorised": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All allowed"])}
    },
    "navigation": {
      "listAccessType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar de lijst van toegangstypen"])},
      "listPersonalisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar de lijst van aanpassingen"])},
      "list-provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar de lijst van leveranciers"])},
      "list-activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar de lijst met gebeurtenissen"])},
      "searchCharacterLimiter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul alstublieft minimaal 3 tekens in"])}
    },
    "config": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bij het aanmaken van een Vigik+ compatibele lezer moet u het type lezer kiezen"])},
      "subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiermee kunt u het Vigik-profiel definiëren dat zal worden gebruikt"])},
      "accessType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type toegang"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "accessNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschrijving"])},
      "createAccessType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een toegangstype creëren"])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acties"])},
      "confirmPoliticsUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een nieuwe Vigik-polis beschikbaar. Wilt u er gebruik van maken?"])}
    },
    "custom": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pas het type toegang ", _interpolate(_named("naam")), " aan"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definieer op deze pagina de activiteiten van de aanbieders"])},
      "activityName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam van de activiteit"])},
      "providerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of Provider"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verboden aanbieders"])},
      "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geautoriseerd"])},
      "noAuthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized"])},
      "reset-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De standaardtijden opnieuw instellen"])},
      "reset-to-zero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijdschema op de standaardwaarde terugzetten"])},
      "reset-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De standaard toegangstypen opnieuw instellen"])},
      "raz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wilt u de standaardinstellingen herstellen ?"])},
      "activityStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity status"])},
      "activityTimeRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity timetable"])},
      "providerTimeRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provider's opening hours"])},
      "timetablesCustomised": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalized schedules"])},
      "timetablesDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default Schedules"])}
    },
    "schedule": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Selecteer de gewenste tijden voor ", _interpolate(_named("naam"))])},
      "edit-schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wilt u uw wijzigingen opslaan?"])},
      "edit-schedule-cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wilt u uw wijzigingen annuleren?"])},
      "update-schedule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Het tijdschema voor ", _interpolate(_named("name")), " is gewijzigd."])},
      "reset-schedule-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please modify the timetables so that you can reset them."])},
      "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timetables"])},
      "selectAllDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De volledige dag selecteren"])}
    },
    "activity": {
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for an activity or a service provider"])},
      "modification": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " wijzigen"]), _normalize([_interpolate(_named("count")), " wijzigingen"])])}
    },
    "provider": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Geautoriseerde providers voor ", _interpolate(_named("naam"))])},
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam van de dienstverlener"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a provider to customize"])},
      "customProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customize a provider"])},
      "searchCustomProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a provider to customize"])},
      "forbiddenProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banning a provider"])},
      "searchForbiddenProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a provider to ban"])}
    },
    "access": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lijst van toegangen van het type ", _interpolate(_named("naam"))])},
      "alert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have ", _interpolate(_named("count")), " access outside your management"])},
      "residence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam van de woning"])},
      "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebouw"])},
      "cage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kooi"])},
      "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegang"])},
      "delete-access-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wilt u dit type toegang verwijderen?"])},
      "update-access-check": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("name")), " accesses have been modified."])},
      "create-access-type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Het type toegang ", _interpolate(_named("name")), " is succesvol aangemaakt."])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let op, alle tot nu toe gemaakte aanpassingen zullen worden verwijderd als u deze herstelling bevestigt. Wilt u de standaardinstellingen herstellen?"])}
    }
  },
  "exportFobs": {
    "fobsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lijst van de badges"])},
    "fobsNumber": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 badges"]), _normalize(["1 badge"]), _normalize([_interpolate(_named("count")), " badges"])])},
    "fobsCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minCount")), " tot ", _interpolate(_named("maxCount"))])},
    "fobsSerial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer van de badge"])},
    "fobsLastUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum van laatste gebruik"])},
    "fobsFilterDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Kies een periode -"])},
    "fobsFilterOneWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouder dan 1 week"])},
    "fobsFilterTwoWeeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouder dan 2 weken"])},
    "fobsFilterOneMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouder dan 1 maand"])},
    "fobsFilterThreeMonths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouder dan 3 maanden"])},
    "fobsFilterSixMonths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouder dan 6 maanden"])},
    "fobsFilterOneYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouder dan 1 jaar"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek een badge of appartement"])},
    "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lijst van badges leeg"])},
    "noDataSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen data gevonden die met uw zoekopdracht overeenkomen"])},
    "fobsDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u zeker dat u deze badge wilt verwijderen? Deze handeling kan niet ongegdaan worden gemaakt."])}
  },
  "publicHome": {
    "residenceTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een wooncomplex overzetten"])},
    "manuals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalogi en handleidingen"])},
    "returnCoupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een retourbon invullen"])},
    "2gInfos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info réseau 2G"])},
    "programmerSetup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De programmatuur installeren"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De algemene voorwaarden raadplegen"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naar de technische ondersteuning"])}
  },
  "navigation": {
    "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn residenties"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groepssleutels"])},
    "bulletinBoard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informatiescherm"])},
    "timeSlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijdsbereiken"])},
    "manageSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheren / zoeken"])},
    "tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onze andere tools"])},
    "texts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS versturen"])},
    "vigik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vigik diensten (in Nederland en België niet beschikbaar)"])},
    "propass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ProPass (in Nederland en België niet beschikbaar)"])},
    "programmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmering"])},
    "programmation2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmering 2"])},
    "keySafe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sleutelkluis"])},
    "provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviceprovider"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afvalbak"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug"])},
    "listBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar mijn residenties"])},
    "residenceBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar de residentie"])},
    "buildingBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar het gebouw"])},
    "stairwellBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar het trappenhuis"])},
    "old_website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oude versie van het portaal"])},
    "vigik3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vigik+ diensten"])}
  },
  "login": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw gebruikersnaam"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw wachtwoord"])},
    "connexion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinding"])},
    "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitloggen"])},
    "forgottenPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik ben mijn wachtwoord vergeten"])},
    "noAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik heb nog geen account"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welkom in uw beheeromgeving"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intratone biedt een scala aan toegangscontrole- en intercomsystemen voor appartementsgebouwen. Een verbonden concept, dat even eenvoudig als innovatief is."])}
  },
  "register": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een account aanmaken"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw gebruikersnaam"])},
    "idSubtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Uw volledige gebruikersnaam is: ", _interpolate(_named("username"))])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw wachtwoord"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestig uw wachtwoord"])},
    "anssiPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw wachtwoord moet minimaal het volgende bevatten: "])},
    "passwordRule1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 karakters"])},
    "passwordRule2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 hoofdletter"])},
    "passwordRule3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 kleine letter"])},
    "passwordRule4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 cijfer"])},
    "passwordRule5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 speciaal karakter"])},
    "passwordSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw wachtwoord dient te hebben:"])},
    "passwordRules1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["een lengte van minimaal 8 tekens"])},
    "passwordRules2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aan deze 3 vereisten dient te worden voldaan:"])},
    "passwordRules3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minstens een hoofdletter"])},
    "passwordRules4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minstens een kleine letter"])},
    "passwordRules5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minstens een cijfer"])},
    "passwordRules6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minstens een speciaal teken (,.!?#= etc.)"])},
    "doubleAuth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activeer 2FA (2-factor authenticatie). Elke keer dat u inlogt, zult u een eenmalige code ontvangen op het e-mailadres dat u hebt opgegeven. Deze optie kan op elk moment worden uitgeschakeld."])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw achternaam"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw voornaam"])},
    "adress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw adres"])},
    "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw postcode"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw stad"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw land"])},
    "chooseCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer uw land"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw bedrijf"])},
    "industry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw branche"])},
    "chooseIndustry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies de sector waarin u actief bent"])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw telefoonnummer"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw e-mailadres"])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepteer de"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gebruiksvoorwaarden"])},
    "alertSpecialCharacter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Letters (a-z), cijfers (0-9) en tekens (", _interpolate(_named("specialCharacter")), ") zijn toegestaan, maar spaties zijn niet toegestaan."])}
  },
  "forgottenPassword": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bent u uw wachtwoord vergeten?"])},
    "giveMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer het e-mailadres van de account in, wij sturen u instructies om uw wachtwoord te vernieuwen."])},
    "mailOrId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres of gebruikersnaam"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw wachtwoord"])},
    "confirmNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestig uw wachtwoord"])},
    "error": {
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De twee wachtwoorden zijn verschillend."])},
      "codeProblem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controleer de code"])},
      "notRespect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw wachtwoord voldoet niet aan de aanbevelingen."])}
    },
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw wachtwoord is gewijzigd. U wordt naar de inlogpagina gestuurd over 10 seconden."])}
  },
  "support": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Software voor ondersteuning door onze technische support"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruik deze links om wijzigen op afstand door een supportmedewerker tijdens een telefoongesprek mogelijk te maken."])}
  },
  "residencesList": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn residenties"])},
    "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een residentie zoeken"])},
    "display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weergave"])},
    "listing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " residentie"]), _normalize([_interpolate(_named("count")), " residentie"]), _normalize([_interpolate(_named("count")), " residenties (", _interpolate(_named("first")), " tot en met ", _interpolate(_named("last")), ")"])])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een residentie aanmaken"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een residentie wijzigen"])},
    "caption": {
      "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standaardwaarde"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VGepersonaliseerde waarde"])}
    },
    "arrayColumns": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
      "adress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
      "updateStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status van de update"])}
    },
    "form": {
      "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keer terug naar de lijst met residenties"])},
      "types": {
        "title-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compleet"])},
        "title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereenvoudigd"])},
        "title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basis"])},
        "detail-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw wooncomplex bestaat uit meerdere gebouwen of heeft meerdere trappenhuizen."])},
        "detail-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw wooncomplex heeft één trappenhuis en meerdere appartementen."])},
        "detail-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U wilt een parkeergarage beheren met alleen HF-ontvangers."])}
      },
      "residence-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam van de residentie"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
      "postal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaats"])},
      "vigik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afdeling Vigik+"])},
      "vigik-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controleer de Vigik+ provincie die u heeft ingevoerd"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaar"])},
      "agence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kantoor"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wilt u uw residentie verwijderen ?"])},
      "locative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huurbeheer activeren"])},
      "liberal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheer vrije beroepen"])},
      "rgpd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AVG-gebeurtenissen"])},
      "delete-residence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residentie verwijderen"])},
      "config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuratie"])},
      "del": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijderen"])},
      "advancedOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geavanceerde opties"])},
      "residenceType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type residentie"])},
      "autorisation": {
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standaardautorisatie"])},
        "command": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standaardautorisatie (afstandsbediening)"])},
        "mobil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standaardautorisatie (clémobil)"])},
        "ipass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residentie beheerd via iPass'"])}
      }
    },
    "modal": {
      "rgpd": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewoners"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijven"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveranciers"])},
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gepersonaliseerd"])},
        "detail-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U bent een verhuurder of een syndicaat van mede-eigenaars: De toegangscontrole wordt gebruikt door huurders of eigenaren. De gebeurtenissen zullen anoniem zijn."])},
        "detail-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U bent een privaat of openbaar bedrijf: De toegangscontrole wordt gebruikt door medewerkers van mijn bedrijf. De gebeurtenissen zullen 3 maanden zichtbaar zijn. Het gebruik van dit statuut op bewoners is strafbaar volgens de wet."])},
        "detail-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De toegangscontrole wordt gebruikt door externe leveranciers (schoonmaakbedrijf, tuinier etc.) van mijn bedrijf. De gebeurtenissen zullen 3 dagen zichtbaar zijn. Het gebruik van dit statuut op bewoners is strafbaar volgens de wet."])},
        "detail-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veiligheidsniveau"])},
        "cage-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam van het trappenhuis"])}
      }
    }
  },
  "entityDetails": {
    "tabs": {
      "residenceDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details van de residentie"])},
      "buildingDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details van het gebouw"])},
      "stairwellDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details van het trappenhuis"])},
      "apartmentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details van het appartement"])},
      "authorisations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisaties"])},
      "codes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codes"])},
      "centralUnits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centrale units"])},
      "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geavanceerde opties"])},
      "liberale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrij beroep"])},
      "subaccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subaccounts"])},
      "intercoms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weergegeven op de intercoms"])},
      "connectit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect.it-bereik"])}
    },
    "actions": {
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmaken"])},
      "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importeren"])},
      "assistedImport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importeren met ondersteuning"])},
      "manualImport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handmatige invoer"])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporteren"])},
      "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neem contact op met Support"])},
      "stairwellExportA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appartement - badge(s) - Nb badge(s) - Na(a)m(en) - Gebruikersnaam - Naam - Handtekening"])},
      "stairwellExportB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appartement - badge(s) - Nb badge(s) - Na(a)m(en) - Gebruikersnaam - P Tel - S Tel - Oproep - Groep"])},
      "stairwellExportC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanwezigheidslijst"])}
    },
    "externalAccesses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegang buiten het gebouw"])},
    "internalAccesses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegang en hardware"])},
    "accessories": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Geen accessoire"]), _normalize([_interpolate(_named("count")), " accessoire"]), _normalize([_interpolate(_named("count")), " accessoires"])])},
    "resident": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Geen bewoner"]), _normalize([_interpolate(_named("count")), " bewoner"]), _normalize([_interpolate(_named("count")), " bewoners"])])},
    "floor": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Begane grond"]), _normalize([_interpolate(_named("count")), "ste verdieping"]), _normalize([_interpolate(_named("count")), "e verdieping"])])},
    "basement": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Begane grond"]), _normalize([_interpolate(_named("count")), "ste kelder"]), _normalize([_interpolate(_named("count")), "e kelder"])])}
  },
  "accessCard": {
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een toegang aanmaken"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebeurtenissen"])},
    "codes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codes"])},
    "badges": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Badges/Afstandsbedieningen (", _interpolate(_named("count")), ")"])},
    "opendoor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegang openen"])}
  },
  "apartmentsList": {
    "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Appartementen ", _interpolate(_named("count"))])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een appartement aanmaken"])},
    "arrayColumns": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
      "firstResident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eerste fiche"])},
      "residents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewoner(s)"])},
      "badges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Badge(s)"])}
    },
    "noAuthorizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U bent niet gerechtigd om de appartementen te raadplegen."])}
  },
  "intercomsList": {
    "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Op de intercoms weergegeven (", _interpolate(_named("count")), ")"])},
    "arrayColumns": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])}
    }
  },
  "codesList": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De codes per toegang"])},
    "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " toegang"]), _normalize([_interpolate(_named("count")), " toegang"]), _normalize([_interpolate(_named("count")), " toegangen"])])},
    "keypadsCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toetsenbordcodes"])},
    "phoneCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefooncodes"])}
  },
  "centralUnitsList": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centrale unit"])},
    "listing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " centrale unit"]), _normalize([_interpolate(_named("count")), " centrale unit"]), _normalize([_interpolate(_named("count")), " centrale units (", _interpolate(_named("first")), " tot en met ", _interpolate(_named("last")), ")"])])},
    "realTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real-time"])},
    "readWrite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read/Write"])},
    "lora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect-it compatibel"])},
    "searchCentral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een centrale zoeken"])},
    "arrayColumns": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
      "serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serienummer"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoonnummer"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "updateStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status van de update"])},
      "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opties"])}
    }
  },
  "keyCard": {
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Badge aanmaken"])},
    "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Badge"])},
    "teleco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afstandsbediening"])},
    "serial": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["n°", _interpolate(_named("serial"))])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u zeker dat u deze badge wilt verwijderen?"])},
    "mobil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile key"])},
    "kibolt": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kibolt"])},
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze sleutel moet geladen worden"])},
      "lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze sleutel is als verloren gemeld"])}
    },
    "copie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Badge gekopieerd"])},
    "colors": {
      "black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwart"])},
      "beige": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beige"])},
      "green": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groen"])},
      "blue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blauw"])},
      "red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rood"])},
      "brown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruin"])},
      "grey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grijs"])},
      "yellow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geel"])},
      "orange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oranje"])},
      "purple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paars"])},
      "white": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wit"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere"])}
    }
  },
  "residenceCard": {
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een residentie aanmaken"])}
  },
  "residentCard": {
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een fiche aanmaken"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikersnaam"])},
    "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
    "mainNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primair telefoonnummer"])},
    "sideNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secundair telefoonnummer"])},
    "delete": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Weet u zeker dat u de bewoner ", _interpolate(_named("naam")), " wilt verwijderen?"])}
  },
  "stairwellCard": {
    "createStairwell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trappenhuis aanmaken"])},
    "createBuilding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebouw aanmaken"])},
    "accesses": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Toegang (", _interpolate(_named("count")), ")"])},
    "stairwells": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " trappenhuis"]), _normalize([_interpolate(_named("count")), " trappenhuizen"])])},
    "apartments": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " appartement"]), _normalize([_interpolate(_named("count")), " appartementen"])])}
  },
  "subaccountsList": {
    "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Subaccount (", _interpolate(_named("count")), ")"])},
    "arrayColumns": {
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikersnaam"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
      "fisrtName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres"])}
    }
  },
  "agencesList": {
    "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kantoren (", _interpolate(_named("count")), ")"])},
    "arrayColumns": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
      "adress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmerking"])}
    }
  },
  "text": {
    "userDataLaws": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U heeft het recht om de betreffende gegevens op te vragen, te wijzigen, te corrigeren en te verwijderen (art. 34 van de Franse Wet Gegevensbescherming). Om dit recht te gebruiken, wendt u zich tot"])},
    "contactEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail : "])},
    "emailIntratoneSuffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["intratone.nl"])},
    "linkEmailIdentity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["support"])},
    "linkEmailDomain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["intratone.nl"])},
    "contactAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adress : Kuiperbergweg 40"])},
    "contactCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1101 AG AMSTERDAM"])},
    "contactPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tel : +31 (0)20 788 3401"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nee"])}
  },
  "brands": {
    "windows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Windows"])},
    "apple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apple"])}
  },
  "bloc_erp": {
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["van"])},
    "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bij"])},
    "nom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
    "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoon"])},
    "visio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video-oproep"])},
    "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres"])},
    "appPC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noodzakelijk voor video-oproepen op de PC"])},
    "telsec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secundaire telefoon"])},
    "visiosec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video-oproep op secundaire telefoon"])},
    "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kleur"])}
  },
  "redirect": {
    "https": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omleiding naar de HTTPS-site"])},
    "http": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omleiding naar de HTTP-site"])}
  },
  "MCI": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon Compte Intratone"])},
    "select": {
      "gestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je suis gestionnaire"])},
      "resident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je suis résident"])}
    },
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om in uw bewonersaccount te komen, klikt u op de knop hieronder."])}
  },
  "module": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te vervangen modules"])},
    "excelExport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporteren naar Excel"])},
    "replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vervangen"])},
    "table": {
      "centraleName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centrale"])},
      "serialModule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serienummer van de module"])},
      "ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referentie voor vervanging"])},
      "portaconnectCompatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portaconnect compatibele referentie"])},
      "replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vervang de module"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken naar een residentie of een serienummer van een module"])},
      "portaconnectInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als het een poort is, kunt u profiteren van het Portaconnect-aanbod. Klik <a href=\"https://portaconnect.intratone.fr/\">hier</a> voor meer informatie."])},
      "finishDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop datum"])},
      "PREPAID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einde vooruitbetaling"])},
      "2G": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einde 2G"])}
    },
    "toReplace": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe module"])},
      "replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te vervangen module"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huidig telefoonnummerl"])},
      "centrale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam van de centrale"])}
    },
    "replacement": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Module vervangen"])},
      "newPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw telefoonnummer"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile key downloaden"])}
    },
    "error": {
      "notCompatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze module is niet compatibel om de hardware ter plaatse te vervangen"])},
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let Op !"])},
      "explication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om de vervanging te bevestigen, download a.u.b. de lijst met de betreffende mobile keys, zodat u de betreffende bewoners kunt informeren."])},
      "isNotFinish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als je de pagina verlaat zonder de vervanging in te vullen, wordt deze niet in aanmerking genomen. Wilt u de pagina verlaten?"])},
      "noDataAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List van modules om te vervangen. Leeg"])},
      "noDataSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen data gevonden die met uw zoekopdracht overeenkomen."])}
    },
    "success": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Er is rekening gehouden met de vervanging van de ", _interpolate(_named("oldName")), " module door de ", _interpolate(_named("newName")), " module."])},
      "explication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze module zal worden bijgewerkt zodra hij is ingeschakeld. De vervanging in de beheeromgeving zal op dat moment plaatsvinden."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De vervanging afsluiten"])}
    },
    "infos": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sommige Intratone GSM-modules zullen in de toekomst functionaliteiten verliezen. Om de functionaliteit van uw installatie te behouden, moet uw module worden vervangen. Het verlies van functionaliteiten kan twee oorzaken hebben:"])},
      "ended2G": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einde van het 2G-netwerk"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nederlandse telecomaanbieders hebben besloten het telecomlandschap aan te passen, wat leidt tot belangrijke veranderingen in het mobiele netwerk. Hoewel eerder werd gecommuniceerd dat het 2G-netwerk eind 2025 zou stoppen, hebben providers dit inmiddels aangepast: KPN heeft de beëindiging van 2G verschoven naar december 2027. Vodafone blijft 2G ondersteunen tot minimaal eind 2026."])},
        "changeCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze technologische verandering, buiten onze controle, zal leiden tot het verlies van bepaalde functionaliteiten. Wij raden aan om de apparatuur te vervangen."])},
        "languageVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nederlandse versie"])}
      },
      "endedContract": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contractverloop"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw 10 jaar pre-paid datacontract loopt binnenkort af. In dat geval raden wij aan om de apparatuur te vernieuwen om alle functionaliteiten te behouden."])}
      }
    }
  },
  "date": {
    "january": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " januari ", _interpolate(_named("year"))])},
    "february": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " februari ", _interpolate(_named("year"))])},
    "march": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " maart ", _interpolate(_named("year"))])},
    "april": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " april ", _interpolate(_named("year"))])},
    "may": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " mei ", _interpolate(_named("year"))])},
    "june": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " juni ", _interpolate(_named("year"))])},
    "july": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " juli ", _interpolate(_named("year"))])},
    "august": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " augustus ", _interpolate(_named("year"))])},
    "september": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " september ", _interpolate(_named("year"))])},
    "october": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " oktober ", _interpolate(_named("year"))])},
    "november": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " november ", _interpolate(_named("year"))])},
    "december": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " december ", _interpolate(_named("year"))])}
  },
  "kibolt": {
    "connected_key_programmer_but_nothing_happens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heeft u uw sleutel in de programmeur geplaatst, maar gebeurt er niets?"])},
    "connected_cylinder_programmer_but_nothing_happen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heeft u uw cylinder aan de programmeur gekoppeld, maar gebeurt er niets?"])},
    "plug_in_programmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluit uw programmeur aan."])},
    "key_is_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De sleutel is verwijderd"])},
    "key_is_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De sleutel is bijgewerkt"])},
    "delete_failed_restart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijdering is mislukt. Begin opnieuw."])},
    "update_failed_restart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update is mislukt. Begin opnieuw."])},
    "currently_being_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wordt verwijderd"])},
    "currently_updating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wordt nu bijgewerkt"])},
    "then_wait_few_seconds_be_detected_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["...wacht enkele seconden voordat hij is gedetecteerd door de app."])},
    "plug_then_wait_seconds_to_detected_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluit het apparaat aan en wacht enkele seconden voordat het door de app gedetecteerd wordt."])},
    "your_programmer_not_responding_even_though_it_is_plugged_in?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reageert uw programmeur niet terwijl hij wel is aangesloten?"])},
    "replace_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vervang de cylinder"])},
    "move_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verplaats de cylinder"])},
    "remove_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder de cylinder"])},
    "size_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grootte van de cylinder"])},
    "cylinder_detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cylinder gedetecteerd"])},
    "insert_replacement_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaats de vervangende cylinder"])},
    "insert_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaats de cylinder"])},
    "insert_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaats de sleutel"])},
    "wait_seconds_detected_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["...wacht nu enkele seconden tot hij door de app gedetecteerd wordt."])},
    "keys_still_open_until_updated_or_barrel_is_re_declared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sleutels zullen nog steeds kunnen openen, tot deze worden geüpdatet"])},
    "unplug_kiprog_duration_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trek de stekker gedurende de update niet uit de Kiprog."])},
    "replacement_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vervanging bezig."])},
    "can_remove_kiprog_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt de Kiprog uit de cylinder verwijderen."])},
    "replacement_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vervanging beëindigd"])},
    "be_careful_cylinder_not_functional_try_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let op, de cylinder is niet functioneel. Probeer a.u.b. opnieuw."])},
    "updates_completed_remove_kiprog_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update is voltood. U kunt de Kiprog uit de cylinder verwijderen."])},
    "update_progress_not_unplug_Kiprog_duration_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update is bezig. Trek de stekker niet uit de Kiprog gedurende de update."])},
    "requires_administrator_rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator-rechten vereist"])},
    "remote_control_and_badge_programmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmeur voor afstandsbedieningen en badges"])},
    "kibolt_key_programmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmeur voor Kibolt"])},
    "unknown_error_contact_customer_or_try_later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een onbekende fout opgetreden. Neem a.u.b. contact op met de klantenservice of probeer het aan het eind van de dag opnieuw"])},
    "error_occurred_during_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijdens de update is er een fout opgetreden."])},
    "cylinder_already_exists_insert_another_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De cylinder in de programmeur bestaat niet of is al in gebruik. Plaats a.u.b. een andere cylinder."])},
    "cylinder_not_correspond_cylinder_updated_insert_correct_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De cylinder in de programmeur komt niet overeen met de cylinder die bijgewerkt moet worden. Plaats a.u.b. de juiste cylinder"])},
    "you_need_update_programmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U moet de versie van uw programmeur updaten om accessoires toe te voegen of bij te werken."])},
    "name_door": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam van de deur"])},
    "type_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type cylinder"])},
    "version_soft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soft-versie"])},
    "version_boot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boot-versie"])},
    "this_is_name_door": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit is de naam van de deur"])},
    "kibolt_cylinder_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kibolt cylinder updates"])},
    "kibolt_cylinder_replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kibolt cylinder vervanging"])},
    "simple_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cylinder voor enkelvoudige deur"])},
    "double_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cylinder voor dubbele deur"])},
    "button_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cylinder voor deur met deurknop"])}
  },
  "TO_UPDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bijwerken"])},
  "INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berichten"])},
  "TECHNICAL_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technische details"])},
  "TRY_AGAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opnieuw proberen"])},
  "CLICK_HERE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik hier"])},
  "REPLACEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vervanging"])},
  "UPDATING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bijgewerkt"])},
  "CONNECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbonden"])},
  "NOT_CONNECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet verbonden"])},
  "INSTALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installeren"])},
  "SERIAL_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serienummer"])},
  "VERSION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versienummer"])},
  "END": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beëindigen"])},
  "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleren"])},
  "COMMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaar"])},
  "SHOW_MORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer tonen"])},
  "SERIAL_NUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serienummer"])},
  "ADVANCED_OPTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geavanceerde opties"])},
  "MOVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verplaatsen naar"])},
  "KIBOLT_ACCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kibolt Toegang"])}
}