<template>
    <div class="infos-layout-container">
        <div class="image-wrapper">
            <img
                src="@/assets/images/intratone/logo-intratone-clipped.svg"
                alt=""
            />
        </div>
        <div class="content-container">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.infos-layout-container {
    width: 100%;
    min-height: 100svh;
    display: flex;
    flex-direction: column;

    .image-wrapper {
        padding: 2em 3em;
        background: $blue-neutral;
        border-radius: 0 0 20px 20px;

        @media all and (max-width: 1024px) {
            display: flex;
            justify-content: center;
            padding: 1.5em 2em;
        }
    }

    .content-container {
        width: 100%;
        max-height: 100svh;
        flex-grow: 1;
        background: $white;
        border-radius: 40px 40px 0 0;
    }
}
</style>
