export default [
    {
        path: "/infos/module_replace",
        name: "ModuleReplaceInfos",
        meta: {
            layout: "InfosLayout",
        },
        component: () => import("@/views/infos/ModuleReplace.vue"),
    },
]
