export default {
  "module": {
    "infos": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um die Funktionalität Ihrer Anlage zu erhalten, muss Ihr Modul ausgetauscht werden. Dieser Austausch ist aus einem der folgenden Gründe erforderlich."])},
      "endedContract": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Vertrag läuft ab"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Servicevertrag, der 10 Jahre Prepaid-Kommunikation umfasst, läuft möglicherweise aus. Wenn dies der Fall ist, empfehlen wir, das Gerät zu erneuern."])}
      },
      "ended2G": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ende des 2G-Netzes"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je nach Betreiber kann das Datum der Abschaltung des 3G-Netzes in den kommenden Monaten variieren. Derzeit wird die vollständige Abschaltung des 3G-Netzes in Ihrem Gebiet für Dezember 2025 erwartet. Dieser technologische Wandel, auf den wir keinen Einfluss haben, wird den Verlust bestimmter Funktionen zur Folge haben. Wir empfehlen, die Geräte auszutauschen."])},
        "changeCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um die Abschaltdaten für andere Länder zu überprüfen, wählen Sie bitte das entsprechende Land oben auf der Seite aus"])},
        "languageVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schweizer version"])}
      }
    }
  }
}