export default {
  "module": {
    "infos": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour le maintien des fonctionnalités de votre installation, votre module doit être remplacé. Ce remplacement s’impose pour l’une des raisons suivantes."])},
      "endedContract": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrat à échéance"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est possible que votre contrat de services, basé sur 10 ans de communications prépayées, arrive à son terme. Dans ce cas, il est recommandé de procéder au renouvellement du matériel."])}
      },
      "ended2G": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin du réseau 2G"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selon les différents opérateurs, la date d'arrêt du réseau 3G peut varier dans les prochains mois. Actuellement, il est prévu que l'intégralité du réseau 3G disparaisse sur votre territoire d'ici fin 2025. Cette évolution technologique, indépendante de notre contrôle, impliquera la perte de certaines fonctionnalités.  Il est recommandé de procéder au renouvellement du matériel."])},
        "changeCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour visualiser, les dates d'arrêt des autres pays, veuillez changer le pays en haut de la page."])},
        "languageVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version française"])}
      }
    }
  }
}