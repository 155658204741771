export default {
  "button": {
    "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
    "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
    "resetStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vider le store"])},
    "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir plus"])},
    "seeLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir moins"])},
    "seeAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir tout"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "see": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir"])},
    "ignore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorer"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuser"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
    "leave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitter"])}
  },
  "link_words": {
    "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À"])}
  },
  "input": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez renseigner ce champ"])},
    "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ est obligatoire"])},
    "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ est invalide"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code rempli n'est pas bon."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette adresse n'est pas valide"])},
    "length": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Doit faire ", _interpolate(_named("length")), " caractères"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher..."])}
  },
  "errors": {
    "oups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oups !"])},
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
    "401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos droits ne vous permettent pas d'afficher cette page."])},
    "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de charger cette page, elle n'existe pas !"])},
    "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur interne"])},
    "contactSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue, veuillez contacter le Support"])}
  },
  "alerts": {
    "connection": {
      "connected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous êtes connecté en tant que ", _interpolate(_named("name"))])},
      "disconnected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes déconnecté"])},
      "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre session a expiré, merci de vous reconnecter"])},
      "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion impossible"])},
      "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de connexion invalides"])},
      "registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte a été enregistré"])}
    },
    "form": {
      "incorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certaines informations du formulaire sont incorrectes ou incomplètes"])},
      "different": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe et la confirmation du mot de passe doivent être identiques"])},
      "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez accepter les conditions d'utilisation afin de créer un nouveau compte"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez sélectionner un pays"])},
      "industryField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez sélectionner un secteur d'activité"])},
      "invalid_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'heure renseignée n'est pas valide"])},
      "required_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez sélectionner un jour"])},
      "vigik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez sélectionner un département Vigik+"])},
      "residence_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez sélectionner un type de résidence"])}
    },
    "version": {
      "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charger les améliorations"])},
      "new_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des améliorations sont disponibles"])}
    },
    "notifications": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Vous avez ", _interpolate(_named("count")), " notification"]), _normalize(["Vous avez ", _interpolate(_named("count")), " notifications"])])},
    "globalNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelles notifications"])},
    "no_mobile_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette page n’a pas encore été optimisée pour l’affichage sur mobile. Nous y travaillons actuellement."])},
    "access": {
      "opendoor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La commande d'ouverture a bien été envoyée."])}
    },
    "residence": {
      "rgpd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos préférences RGPD ont été sauvegardés"])}
    },
    "errors": {
      "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet identifiant est déjà utilisé !"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce badge est déjà enregistré dans cette cage d'escalier !"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce code clavier existe déjà pour cet accès !"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce code téléphonique existe déjà pour cet accès !"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette combinaison de nom et identifiant\nexiste déjà pour cet appartement !"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce numéro abrégé est déjà utilisé !"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce jour férié existe déjà !"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce badge est déjà utilisée en tant que clé Pass !"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez dépassé les quotas (Nb de fiches...) de votre Forfait GSM !"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre numéro de contrat GSM n'est pas référencé !"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre centrale ne sera pas mise à jour\navec ce numéro de téléphone\npour un souci de tarification !"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'êtes pas autorisé à modifier ce service !"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet accès est utilisé par une ou plusieurs\nautorisations ou bien connecté à une centrale,\nvous ne pouvez donc pas le supprimer !"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette autorisation est utilisée par une ou plusieurs badges,\nvous ne pouvez pas la supprimer !"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette fonctionnalité n'est disponible que sous Internet Explorer actuellement !"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez créer des appartements pour pouvoir programmer des badges !"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez définir les paramètres de la centrale au préalable !"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce nom de centrale est déjà utilisé !"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce nom de résidence est déjà utilisé !"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce nom de cage d'escalier est déjà utilisé !"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce nom d'appartement est déjà utilisé pour cette cage d'escalier !"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce nom d'autorisation est déjà utilisé !"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce nom d'accès est déjà utilisé !"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce nom de clé Pass existe déjà!"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas créer plus de\n11 codes claviers par porte!"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas créer plus de\n10 codes téléphoniques par porte!"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas créer plus de\n1 code clavier pour cette porte!"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez avoir plus de 16 accès de\ntype Lecture/Ecriture pour une autorisation!"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas programmer plus de 6 residences\nde type Lecture/Ecriture sur cette clé Pass!"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas programmer plus de 6 cages d'escalier\nde type Lecture/Ecriture sur cette clé Pass!"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas programmer plus de 6 accès de\ntype Lecture/Ecriture sur cette clé Pass!"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette centrale est déjà utilisée !"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci de vérifier le numéro de bloc ou de contrat saisi.\nSi votre matériel ne fait pas partie d'un pack,\nveuillez sélectionner un matériel ci-dessous."])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certaines centrales GSM n'ont pas pu être mise\nà jour pour des soucis de quotas de Forfait GSM!"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certaines centrales sont de type Lecture/Ecriture\net nécessitent d'être mise en service!"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette fonctionnalité nécessite l'installation du programmateur\n(disponible uniquement sous Internet Explorer)!"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet accès est utilisé pour une autorisation\nvous ne pouvez pas changer son fonctionnement !"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet accès est utilisé par des codes claviers\nvous ne pouvez pas changer son fonctionnement !"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez connecter uniquement des accès\nde type platine à défilement sur ce connecteur !"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas connecter d'accès de\ntype platine à défilement sur ce connecteur !"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez d'abord connecter cet accès à une centrale !"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce matériel est disponible uniquement en France !"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas programmer plus de 18 residences\nde type Lecture/Ecriture sur cette clé Pass!"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas programmer plus de 18 cages d'escalier\nde type Lecture/Ecriture sur cette clé Pass!"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas programmer plus de 18 accès\nde type Lecture/Ecriture sur cette clé Pass!"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas programmer des résidences\nde type Lecture/Ecriture (HF) sur cette télécommande Pass!"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas programmer des cages d'escalier\nde type Lecture/Ecriture (HF) sur cette télécommande Pass!"])},
      "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas programmer des accès\nde type Lecture/Ecriture (HF) sur cette télécommande Pass!"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas programmer plus de 5 résidences\nde type Lecture/Ecriture (HF) sur cette télécommande Pass!"])},
      "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas programmer plus de 5 cages d'escalier\nde type Lecture/Ecriture (HF) sur cette télécommande Pass!"])},
      "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas programmer plus de 5 accès\nde type Lecture/Ecriture (HF) sur cette télécommande Pass!"])},
      "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez avoir plus de 5 accès de\ntype Lecture/Ecriture (HF) pour une autorisation!"])},
      "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez d'abord connecter cette centrale à un accès !"])},
      "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le numéro de série de la centrale n'est pas valide"])},
      "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez avoir plus de 10 accès de\ntype LIGHT pour une autorisation!"])},
      "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez utiliser des badges ou télécommandes (S)\npour ouvrir ce ou ces types d'accès!"])},
      "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez avoir plus de 10 accès de\ntype LIGHT programmés sur un badge!"])},
      "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun autre appartement disponible dans cette cage d'escalier !"])},
      "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importation terminée avec succès"])},
      "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas supprimer la carte option !\nDu matériel est connecté sur le connecteur 3 ou 4"])},
      "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre programmateur n'est pas compatible avec cette fonctionnalité !"])},
      "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors du chargement\nde la sauvegarde, veuillez réessayer !"])},
      "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La carte inserée n'est pas une carte de sauvegarde !"])},
      "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez si la sauvegarde de la carte est bien inserée !"])},
      "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors du chargement\nde la sauvegarde, veuillez réessayer !"])},
      "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez une sauvegarde connectée sur le connecteur interne et une dans le lecteur à carte à puce..."])},
      "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette Clémobil est déjà enregistrée dans cette cage d'escalier !"])},
      "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre programmateur n'est pas compatible avec cette fonctionnalité !"])},
      "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors du chargement\nde la mémoire, veuillez réessayer !"])},
      "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La carte inserée n'est pas une carte de mémoire !"])},
      "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez si la mémoire de la carte est bien inserée !"])},
      "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors du chargement\nde la mémoire, veuillez réessayer !"])},
      "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La carte inserée n'est pas la carte de mémoire de cette centrale !"])},
      "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez atteint le nombre maximum\nde codes claviers pour cet accès!"])},
      "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet accès n'est pas connectable sur ce type de connecteur !"])},
      "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez atteint le nombre maximum d'essai du service SMS !"])},
      "80": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors de l'envoi du SMS, veuillez réessayer !"])},
      "81": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez atteint le nombre maximum\nde codes claviers pour cette centrale!"])},
      "82": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet accès n'est pas connectable que sur le connecteur n°1 !"])},
      "83": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet accès n'est pas connectable sur ce type de centrale ou ce numéro de connecteur !"])},
      "84": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ces codes sont inconnus !"])},
      "85": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce code produit est déjà activé !"])},
      "86": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez atteint le nombre maximum de badges pour cet appartement!"])},
      "87": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez atteint le nombre maximum de télécommandes pour cet appartement!"])},
      "88": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seules les lettres (a-z)(A-Z), les chiffres (0-9) et les caractères (.,-,_) sont autorisés, les espaces sont interdits."])},
      "89": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez plus créer de fiches dans cet appartement !"])},
      "90": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner au moins une résidence !"])},
      "91": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner au moins une cage d'escalier !"])},
      "92": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner au moins une porte !"])},
      "93": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner au moins un accès !"])},
      "94": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner au moins une agence !"])},
      "95": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce badge n'existe pas"])},
      "96": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez atteint le nombre maximum de badge gestionnaire attribuable!"])},
      "97": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'utilisateur possède déjà ce type de cle"])},
      "100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet enregistrement a été supprimé !"])},
      "101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez atteint le nombre de jours fériés autorisés!"])},
      "102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce cas d'utilisation est utilisé dans d'autres accès et ne peut être supprimé!"])},
      "103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette semaine est utilisée dans une plage horaire!"])},
      "104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur inexistant !"])},
      "105": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non accessible !"])},
      "106": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet identifiant est déjà utilisé !"])},
      "107": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce nom de batiment est déjà utilisé !"])},
      "108": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de données à traiter !"])},
      "109": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce badge n'est pas présent !"])},
      "110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors du lancement de la mise à jour, veuillez réessayer ultérieurement !"])},
      "111": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce badge n'est pas valide ou est déjà utilisé !"])},
      "112": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce code barre n'existe pas !"])},
      "113": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce code barre est déjà utilisé !"])},
      "114": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le service de création de compte n'est pas accessible, merci d'essayer plus tard."])},
      "115": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce compte ne peut pas être déplacé."])},
      "116": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet utilisateur possède déjà ce type de clé."])},
      "117": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas encore de sous - comptes."])},
      "120": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez une mémoire connectée sur le connecteur interne et une dans le lecteur à carte à puce..."])},
      "200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce nom de plage est déjà utilisé !"])},
      "201": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette plage est utilisée et ne peut être supprimée !"])},
      "202": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune donnée correspond à votre recherche ..."])},
      "203": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe saisi n'est pas valide ..."])},
      "204": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner au moins un destinataire !"])},
      "301": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention vous avez dépassé votre forfait, vous ne pouvez pas mettre à jour votre centrale..."])},
      "302": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention le numéro de téléphone GSM est vide, vous ne pouvez pas mettre à jour votre centrale..."])},
      "303": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention vous avez dépassé le nombre maximum de téléphones Internationaux autorisés..."])},
      "304": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention votre forfait n'est pas compatible avec cette configuration matérielle."])},
      "305": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention votre forfait n'est pas autorisé à utiliser des appels Visios, veuillez souscrire à l'option Visio ou décocher l'option appel visio sur les fiches concernées"])},
      "306": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas déclarer ce type de kit IntraBox Eco, dans une résidence basique."])},
      "700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas programmer plus de dix badges de ce type."])},
      "800": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce contrat GSM est déjà utilisé !"])},
      "801": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue. Merci de contacter le support."])},
      "802": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci de saisir le Numéro de bloc ou le numéro de téléphone."])},
      "803": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun matériel n'est associé à ce contrat."])},
      "804": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce type de contrat n'est pas compatible avec ce type de résidence."])},
      "805": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour déclarer ce module, commencez par choisir votre matériel (interphone, lecteur...)  puis \"Configurer la centrale\""])},
      "806": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention votre forfait est suspendu, merci de contacter INTRATONE (02 51 57 03 07) !"])},
      "1001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une mise à jour de l'écran est en cours... Vous devez patienter ..."])},
      "1002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le texte est trop long, veuillez le raccourcir ..."])},
      "1003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez sélectionner au moins la colonne nom d'appartement!"])},
      "1004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une colonne désigne le nom de la fiche, mais il manque une colonne désignant le téléphone principal!"])},
      "1005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur a interrompu l'importation!"])},
      "1006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un numéro de téléphone secondaire n'est pas valide!"])},
      "1007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un numéro de téléphone principal n'est pas valide!"])},
      "1008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un identifiant n'est pas valide!"])},
      "1009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un nom n'est pas valide!"])},
      "1010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un nom d'appartement n'est pas valide!"])},
      "1011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un numéro de série n'est pas valide!"])},
      "1012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un numéro de téléphone principal est manquant!"])},
      "1013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un identifiant est saisi sans nom de fiche!"])},
      "1014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un numéro de téléphone secondaire est saisi sans nom de fiche!"])},
      "1015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un numéro abrégé est saisi sans nom de fiche!"])},
      "1016": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un groupe d'affichage est saisi sans nom de fiche!"])},
      "1017": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un numéro de téléphone principal est saisi sans nom de fiche!"])},
      "1018": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le format de l'image n'est pas pris en charge !"])},
      "1019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La gestion avancée est impossible, l'utilisateur possède un accès global aux résidences !"])},
      "1020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un numéro abrégé est déja utilisé !"])},
      "1021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La taille de l'image est trop grande !"])},
      "1022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez sélectionner une colonne pour les cages et une colonne pour les noms d'appartement !"])},
      "1023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette image est utilisée, sa suppression est impossible!"])},
      "1024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce type de fichier n'est pas compatible !"])},
      "1200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de l'envoi des données"])},
      "1201": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La durée d'affichage doit être un nombre positif"])},
      "1202": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La date de début n'est pas valide"])},
      "1203": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La date de fin n'est pas valide"])},
      "1204": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La date de début doit précéder la date de fin"])},
      "1205": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de la sauvegarde"])},
      "1206": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner un fichier !"])},
      "1207": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce fichier ne semble pas avoir pu être importé !"])},
      "1300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez définir l'accès concerné"])},
      "1400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression des résidants terminée..."])},
      "1401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression des badges et télécommandes terminée..."])},
      "1500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certaines données du formulaire ne sont pas valides"])},
      "1600": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de trouver les informations concernant l'appartement"])},
      "1601": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone mal formaté"])},
      "1602": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de la récupération du numéro de téléphone"])},
      "1603": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le badge ne peut pas être ajouté"])},
      "1604": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de la récupération des informations sur les badges"])},
      "1605": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas assez de badges d'une certaine typologie"])},
      "1606": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trop de badges d'une certaine typologie"])},
      "1607": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de récupérer les infos sur la programmation"])},
      "1608": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de la création de la fiche"])},
      "1609": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de la mise à jour de la fiche"])},
      "1610": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fiche est déjà associée à un numéro de téléphone"])},
      "1611": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur : plusieurs fiches dans l'appartement"])},
      "1612": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de la récupérations informations sur les fiches"])},
      "1613": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de la création du badge"])},
      "1615": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention, cette action nécessite que vous supprimiez l'antenne Connect.iT reliée à votre module."])},
      "1700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur : Votre compte est déjà un compte administrateur"])},
      "1701": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de passer en compte administrateur"])},
      "1702": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de passer en compte administrateur : votre adresse mail n'est pas renseignée"])},
      "1800": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur : Le nom de la centrale doit être renseigné"])},
      "1801": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur : Problème de configuration"])},
      "1802": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur : Vous devez renseigner le nom de l'accès"])},
      "1803": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur : Votre contrat ne permet pas l'ajout de ce matériel"])},
      "1804": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur : Le matériel est déjà déclaré"])},
      "1805": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur : Impossible d'identifier le contrat, complétez les informations"])},
      "2016": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors de la demande de création de compte."])},
      "2019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue, veuillez réessayer plus tard."])},
      "2020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonction bloquée par votre administrateur."])},
      "API_RESTRICTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas les autorisations nécessaires à cette action."])},
      "PARAMS_INVALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue, veuillez réessayer plus tard."])},
      "PARAMS_MISSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue, veuillez réessayer plus tard."])},
      "ACCES_TYPEEXIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur : Le type d'accès est déjà déclaré"])},
      "ACCES_TYPEFAILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors de la demande de création d'un type d'accès."])},
      "POLICY_RESTRICTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas les autorisations nécessaires à cette action."])},
      "WD_0_TIME_END_HIGHER_TIME_START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un créneau horaire du lundi à une heure de fin supérieur à celle du début"])},
      "WD_1_TIME_END_HIGHER_TIME_START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un créneau horaire du mardi à une heure de fin supérieur à celle du début"])},
      "WD_2_TIME_END_HIGHER_TIME_START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un créneau horaire du mercredi à une heure de fin supérieur à celle du début"])},
      "WD_3_TIME_END_HIGHER_TIME_START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un créneau horaire du jeudi à une heure de fin supérieur à celle du début"])},
      "WD_4_TIME_END_HIGHER_TIME_START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un créneau horaire du vendredi à une heure de fin supérieur à celle du début"])},
      "WD_5_TIME_END_HIGHER_TIME_START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un créneau horaire du samedi à une heure de fin supérieur à celle du début"])},
      "WD_6_TIME_END_HIGHER_TIME_START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un créneau horaire du dimanche à une heure de fin supérieur à celle du début"])},
      "WD_0_TIME_OVERLAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un chevauchement entre 2 créneaux horaires a été détecté pour le lundi"])},
      "WD_1_TIME_OVERLAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un chevauchement entre 2 créneaux horaires a été détecté pour le mardi"])},
      "WD_2_TIME_OVERLAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un chevauchement entre 2 créneaux horaires a été détecté pour le mercredi"])},
      "WD_3_TIME_OVERLAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un chevauchement entre 2 créneaux horaires a été détecté pour le jeudi"])},
      "WD_4_TIME_OVERLAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un chevauchement entre 2 créneaux horaires a été détecté pour le vendredi"])},
      "WD_5_TIME_OVERLAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un chevauchement entre 2 créneaux horaires a été détecté pour le samedi"])},
      "WD_6_TIME_OVERLAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un chevauchement entre 2 créneaux horaires a été détecté pour le dimanche"])},
      "WD_0_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue, veuillez réessayer plus tard."])},
      "WD_1_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue, veuillez réessayer plus tard."])},
      "WD_2_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue, veuillez réessayer plus tard."])},
      "WD_3_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue, veuillez réessayer plus tard."])},
      "WD_4_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue, veuillez réessayer plus tard."])},
      "WD_5_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue, veuillez réessayer plus tard."])},
      "WD_6_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue, veuillez réessayer plus tard."])},
      "2021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un des champs renseigné n'est pas correct. Veuillez le vérifier."])}
    }
  },
  "language": {
    "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anglais"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir une langue"])}
  },
  "global": {
    "entities": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
      "residence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résidence"])},
      "residenceType": {
        "basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résidence basique"])},
        "simplified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résidence simplifiée"])},
        "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résidence complexe"])}
      },
      "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bâtiment"])},
      "stairwell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cage"])},
      "apartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appartement"])},
      "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès"])},
      "resident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiche résident"])}
    },
    "status": {
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour"])},
      "updateAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout mettre à jour"])},
      "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À jour"])},
      "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours de mise à jour"])},
      "needUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besoin de mise à jour"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de mise à jour"])},
      "commissioning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise en service"])},
      "optionPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option Pass"])},
      "updateResidence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifications de votre résidence appliquées"])},
      "inService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En service"])},
      "notInService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hors service"])},
      "error2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])}
    },
    "modal": {
      "edit-time-range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la plage horaire"])},
      "create-time-range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une plage horaire"])},
      "copie-day-time-range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier les horaires"])},
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention !"])},
      "rgpd": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Conformité RGPD des événements de la résidence : ", _interpolate(_named("name"))])},
      "deviceNotCompatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'appareil que vous utilisez pour programmer vos accessoires n'est pas compatible avec les programmateurs USB. Veuillez utiliser un ordinateur."])},
      "delete_cylinder?_is_irreversible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer ce cylindre ? Cette action est irréversible."])},
      "window_open_site_in_host_please_close_it": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez déjà une fenêtre ouverte avec l'utilisation d'un programmateur sur le site ", _interpolate(_named("name")), ". Veuillez la fermer pour pouvoir utiliser vos programmateurs sur cette page."])},
      "device_using_program_accessories_not_compatible_USB_programmers_use_computer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'appareil que vous utilisez pour programmer vos accessoires n'est pas compatible avec les programmateurs USB. Veuillez utiliser un ordinateur."])},
      "you_need_update_programmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez mettre à jour la version de votre programmateur pour pouvoir ajouter ou mettre à jour des accessoires."])},
      "valid": {
        "week-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider les nouvelles plages horaires"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etes vous sur de vouloir annuler les modifications ?"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez valider les modifications"])},
        "raz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialisation"])},
        "access-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider le nouveau type d'accès"])},
        "delete-access-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhaitez-vous supprimer ce type d'accès ?"])},
        "delete-residence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la résidence"])},
        "politicsUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modification Vigik en attente"])},
        "politicsUpdateText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention, toute personnalisation effectuée précédemment sera supprimée si vous validez cette mise à jour. Souhaitez-vous l'utiliser ?"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rétablir les paramètres par défaut"])}
      },
      "vigik": {
        "not-enough-time-slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez atteint le nombre maximal de plages horaires. Veuillez en supprimer pour en créer de nouvelles."])}
      },
      "providersStatus": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Récapitulatif des modifications (", _interpolate(_named("count")), ")"])},
        "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités"])},
        "authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisée"])},
        "noAuthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non autorisée"])}
      },
      "doubleAuth": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double authentification"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un code de validation à usage unique a été envoyé à cette adresse mail :"])},
        "validationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de validation"])},
        "error": {
          "attempt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Le code de validation est incorrect. Il vous reste ", _interpolate(_named("count")), " tentative"]), _normalize(["Le code de validation est incorrect. Il vous reste ", _interpolate(_named("count")), " tentatives"])])},
          "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez recommencer la procédure. Vous allez être redirigé vers la page de connexion dans 10 secondes."])},
          "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code n’est plus valide."])}
        }
      }
    },
    "array": {
      "emptyList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste vide"])},
      "noSearchData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune donnée ne correspond à votre recherche"])}
    }
  },
  "weekType": {
    "weekday": {
      "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lundi"])},
      "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mardi"])},
      "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercredi"])},
      "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeudi"])},
      "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendredi"])},
      "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samedi"])},
      "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimanche"])}
    },
    "copie-choice": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sur quels jours voulez vous copier les horaires du ", _interpolate(_named("day")), " ?"])},
    "max-different-day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez atteint le maximum de jours différent dans le calendrier. Voulez vous réutiliser les horaires d'un autre jour ?"])}
  },
  "vigik": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services Vigik+"])},
    "form": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du type d'accès"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier le fonctionnement"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez vous annuler la création d'un nouveau type d'accès ?"])},
      "create-question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous créer ce nouveau type d'accès ?"])},
      "openingPriority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priorité d'ouverture"])},
      "obligatoryFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champ obligatoire"])},
      "minimalTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La plage horaire doit être supérieure à 15 min"])},
      "opened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvert"])},
      "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermé"])}
    },
    "modale": {
      "copySchedule": {
        "copyTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier le"])},
        "onNextDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sur les jours suivants"])}
      },
      "createSchedule": {
        "limitAtFiveSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas créer plus de cinq plages horaires par jour."])},
        "existingSchedules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il existe déjà un créneau sur ces horaires"])}
      }
    },
    "global": {
      "vigik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vigik"])},
      "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités"])},
      "customProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prestataires personnalisés"])},
      "bannedProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prestataires interdits"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
      "whitelisted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Personnalisé"]), _normalize([_interpolate(_named("count")), " personnalisé"]), _normalize([_interpolate(_named("count")), " personnalisés"])])},
      "blacklisted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Interdit"]), _normalize([_interpolate(_named("count")), " interdit"]), _normalize([_interpolate(_named("count")), " interdits"])])},
      "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par défaut"])},
      "activitiesAllows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité autorisée"])},
      "activitiesBanned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité non autorisée"])},
      "parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])},
      "allAutorised": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous autorisés"])}
    },
    "navigation": {
      "listAccessType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à la liste des types d'accès"])},
      "listPersonalisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à la liste des activités"])},
      "list-provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à la liste des prestataires"])},
      "list-activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à la liste des activités"])},
      "searchCharacterLimiter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez remplir au minimum 3 caractères"])}
    },
    "config": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lors de la création d'un lecteur compatible Vigik+, vous devez choisir le type de lecteur"])},
      "subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cela permet de définir le profil Vigik qui sera utilisé"])},
      "accessType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'accès"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "accessNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'accès"])},
      "createAccessType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un type d'accès"])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
      "confirmPoliticsUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une nouvelle politique Vigik est disponible. Souhaitez-vous l'utiliser ?"])}
    },
    "custom": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnalisation du type d'accès"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définissez dans cette page les activités des prestataires"])},
      "activityName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'activité"])},
      "providerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du prestataire"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
      "provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prestataires avec exception"])},
      "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisé"])},
      "noAuthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non autorisé"])},
      "reset-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remettre les horaires par défaut"])},
      "reset-to-zero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remettre les horaires par défaut"])},
      "reset-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remettre les types d'accès par défaut"])},
      "raz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous réinitialiser avec les paramètres par défaut ?"])},
      "activityStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut de l'activité"])},
      "activityTimeRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horaires de l'activité"])},
      "providerTimeRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horaires du prestataire"])},
      "timetablesCustomised": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horaires personnalisés"])},
      "timetablesDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horaires par défaut"])}
    },
    "schedule": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sélectionner les horaires souhaités pour ", _interpolate(_named("name"))])},
      "edit-schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous sauvegarder vos modifications ?"])},
      "edit-schedule-cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous annuler vos modifications ?"])},
      "update-schedule": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Les horaires de ", _interpolate(_named("name")), " ont bien été modifiés."])},
      "reset-schedule-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez modifier les horaires pour pouvoir les reinitialiser."])},
      "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horaires"])},
      "selectAllDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner la journée entière"])}
    },
    "activity": {
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher une activité ou un prestataire"])},
      "modification": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " modification"]), _normalize([_interpolate(_named("count")), " modifications"])])}
    },
    "provider": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prestataires autorisé pour ", _interpolate(_named("name"))])},
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du prestataire"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher un prestataire"])},
      "customProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnaliser un prestataire"])},
      "searchCustomProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher un prestataire à personnaliser"])},
      "forbiddenProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interdire un prestataire"])},
      "searchForbiddenProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher un prestataire à interdire"])}
    },
    "access": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Liste des accès du type ", _interpolate(_named("name"))])},
      "alert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez ", _interpolate(_named("count")), " accès hors de votre gestion"])},
      "residence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la résidence"])},
      "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bâtiment"])},
      "cage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cage"])},
      "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès"])},
      "delete-access-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez vous supprimer ce type d'accès ?"])},
      "update-access-check": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Les accès de ", _interpolate(_named("name")), " ont bien été modifiés."])},
      "create-access-type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le type d'accès ", _interpolate(_named("name")), " a bien été créé."])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention, toute personnalisation effectuée précédemment sera supprimée si vous validez cette restauration. Souhaitez-vous restaurer les paramètres par défaut ?"])}
    }
  },
  "exportFobs": {
    "fobsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des badges"])},
    "fobsNumber": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 badge"]), _normalize(["1 badge"]), _normalize([_interpolate(_named("count")), " badges"])])},
    "fobsCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minCount")), " à ", _interpolate(_named("maxCount"))])},
    "fobsSerial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° de badge"])},
    "fobsLastUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière date d'utilisation"])},
    "fobsFilterDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Choix d'une période -"])},
    "fobsFilterOneWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au-delà de 1 semaine"])},
    "fobsFilterTwoWeeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au-delà de 2 semaines"])},
    "fobsFilterOneMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au-delà de 1 mois"])},
    "fobsFilterThreeMonths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au-delà de 3 mois"])},
    "fobsFilterSixMonths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au-delà de 6 mois"])},
    "fobsFilterOneYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au-delà de 1 an"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher un badge ou un appartement"])},
    "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des badges vide"])},
    "noDataSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune donnée ne correspond à votre recherche"])},
    "fobsDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer ce badge ? Cette action est irréversible"])}
  },
  "publicHome": {
    "residenceTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transférer une résidence"])},
    "manuals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalogues et notices"])},
    "returnCoupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rédiger un bon de retour"])},
    "2gInfos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info réseau 2G"])},
    "programmerSetup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installer le programmateur"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulter les conditions générales"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accéder à l'assistance technique"])}
  },
  "navigation": {
    "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes résidences"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clés Pass"])},
    "bulletinBoard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau d'affichage"])},
    "timeSlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plages horaires"])},
    "manageSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer / rechercher"])},
    "tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos autres outils"])},
    "texts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoi de sms"])},
    "vigik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services Vigik"])},
    "propass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ProPass"])},
    "programmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmation"])},
    "programmation2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmation 2"])},
    "keySafe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coffre à clés"])},
    "provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prestataire"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aides"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "trash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corbeille"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
    "listBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à mes sites"])},
    "residenceBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à la résidence"])},
    "buildingBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour au bâtiment"])},
    "stairwellBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à la cage"])},
    "old_website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancienne version du site"])},
    "vigik3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services Vigik+"])}
  },
  "login": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre nom d'utilisateur"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe"])},
    "connexion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
    "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
    "forgottenPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai oublié mon mot de passe"])},
    "noAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je n'ai pas encore de compte"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue sur votre espace de gestion"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intratone propose une gamme de controle d'accès et d'interphonie pour l'habitat collectif. Un concept connecté aussi simple qu'innovant."])}
  },
  "register": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un compte"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre nom d'utilisateur"])},
    "idSubtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre nom d'utilisateur complet sera : ", _interpolate(_named("username"))])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmez votre mot de passe"])},
    "anssiPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conformément aux recommandations de l’ANSSI, votre mot de passe doit comporter au minimum : "])},
    "passwordRule1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 caractères"])},
    "passwordRule2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 majuscule"])},
    "passwordRule3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 minuscule"])},
    "passwordRule4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 chiffre"])},
    "passwordRule5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 caractère spécial"])},
    "passwordSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe doit :"])},
    "passwordRules1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avoir une longueur minimum de 8 caractères"])},
    "passwordRules2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remplir 3 de ces conditions :"])},
    "passwordRules3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["une lettre minuscule"])},
    "passwordRules4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["une lettre majuscule"])},
    "passwordRules5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["un chiffre"])},
    "passwordRules6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["un caractère spécial"])},
    "doubleAuth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer la double authentification. À chaque connexion, un code à usage unique sera envoyé à l’adresse mail renseignée. Cette option est désactivable à tout moment."])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre nom"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre prénom"])},
    "adress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre adresse"])},
    "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre code postal"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre ville"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre pays"])},
    "chooseCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez votre pays"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre société"])},
    "industry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre secteur d'activité"])},
    "chooseIndustry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez votre secteur d'activité"])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre numéro de téléphone"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre email"])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter les"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conditions d'utilisation"])},
    "alertSpecialCharacter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Les lettres (a-z), les chiffres (0-9) et les caractères (", _interpolate(_named("specialCharacter")), ") sont autorisés, les espaces sont interdits."])}
  },
  "forgottenPassword": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez oublié votre mot de passe ?"])},
    "giveMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez l’e-mail du compte, nous vous enverrons les instructions pour renouveler votre mot de passe."])},
    "mailOrId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail ou identifiant"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
    "confirmNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmez votre nouveau mot de passe"])},
    "error": {
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les deux mots de passe sont différents."])},
      "codeProblem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez le code"])},
      "notRespect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe ne respecte pas les recommandations de l’ANSSI."])}
    },
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe a bien été modifié. Vous serez redirigé vers la page de connexion dans 10 secondes."])}
  },
  "support": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logiciel d'assistance avec le support technique"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisez ces liens pour permettre un contrôle à distance par un technicien du support lors d'un appel téléphonique."])}
  },
  "residencesList": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes résidences"])},
    "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher un site"])},
    "display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affichage"])},
    "listing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " site"]), _normalize([_interpolate(_named("count")), " site"]), _normalize([_interpolate(_named("count")), " sites (", _interpolate(_named("first")), " à ", _interpolate(_named("last")), ")"])])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une résidence"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier une résidence"])},
    "caption": {
      "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur par défaut"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur personnalisée"])}
    },
    "arrayColumns": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "adress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
      "updateStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut de la mise à jour"])}
    },
    "form": {
      "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à la liste des résidences"])},
      "types": {
        "title-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complète"])},
        "title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simplifiée"])},
        "title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basique"])},
        "detail-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre résidence est composée de plusieurs bâtiments ou de plusieurs cages d'escalier."])},
        "detail-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre résidence est composée d'une seule cage d'escalier contenant plusieurs appartements."])},
        "detail-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous voulez gérer un parking avec des récepteurs HF uniquement."])}
      },
      "residence-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la résidence"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
      "postal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
      "vigik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Département Vigik+"])},
      "vigik-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vérifier le Département Vigik+ saisi"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
      "agence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agence"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous supprimer votre résidence ?"])},
      "locative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer la gestion locative"])},
      "liberal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion profession libérale"])},
      "rgpd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les évènements RGPD"])},
      "delete-residence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la résidence"])},
      "config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
      "del": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
      "advancedOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options avancées"])},
      "residenceType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de résidence"])},
      "autorisation": {
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisation par défaut"])},
        "command": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisation par défaut (télécommande)"])},
        "mobil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisation par défaut (clémobil)"])},
        "ipass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résidence gérée par iPass"])}
      }
    },
    "modal": {
      "rgpd": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résidents"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tertiaire"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prestataires"])},
        "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnalisé"])},
        "detail-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes bailleurs ou syndicat de copropriétaire : Le contrôle d'accès est utilisé par des locataires ou des propriétaires; les évènements seront anonymes."])},
        "detail-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes une entreprise privée ou publique : Le contrôle d'accès est utilisé par des salariés de mon entreprise. Les évènements seront visibles 3 mois. Utiliser ce statut sur des résidents est sanctionné par la loi."])},
        "detail-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le contrôle d'accès est utilisé par des prestataires (Entreprises de ménage, jardinage...) non salarié de mon entreprise. Les évènements seront visibles 3 jours. Utiliser ce statut sur des résidents est sanctionné par la loi."])},
        "detail-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau de sécurité"])},
        "cage-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la cage"])}
      }
    }
  },
  "entityDetails": {
    "tabs": {
      "residenceDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de la résidence"])},
      "buildingDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du bâtiment"])},
      "stairwellDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de la cage"])},
      "apartmentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de l'appartement"])},
      "authorisations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisations"])},
      "codes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codes"])},
      "centralUnits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centrales"])},
      "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion locative"])},
      "liberale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profession libérale"])},
      "subaccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous-comptes"])},
      "intercoms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiché sur les platines"])},
      "connectit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gamme Connect.it"])}
    },
    "actions": {
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer"])},
      "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer"])},
      "assistedImport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importation assistée"])},
      "manualImport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer manuelle"])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter"])},
      "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacter le support"])},
      "stairwellExportA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logement - Badge(s) - Nb badge(s) - Nom(s) - Identifiant - Nom - Signature"])},
      "stairwellExportB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logement - Badge(s) - Nb badge(s) - Nom(s) - Identifiant - Tél P - Tél S - Appel - Groupe"])},
      "stairwellExportC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste d'émargement"])}
    },
    "externalAccesses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès hors bâtiment"])},
    "internalAccesses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès et matériels"])},
    "accessories": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Aucun accessoire"]), _normalize([_interpolate(_named("count")), " accessoire"]), _normalize([_interpolate(_named("count")), " accessoires"])])},
    "resident": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Aucun résident"]), _normalize([_interpolate(_named("count")), " résident"]), _normalize([_interpolate(_named("count")), " résidents"])])},
    "floor": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Rez-de-chaussée"]), _normalize([_interpolate(_named("count")), "er étage"]), _normalize([_interpolate(_named("count")), "ème étage"])])},
    "basement": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), "er sous-sol"]), _normalize([_interpolate(_named("count")), "ème sous-sol"])])}
  },
  "accessCard": {
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un accès"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évènements"])},
    "codes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codes"])},
    "badges": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Badges / Télécommandes (", _interpolate(_named("count")), ")"])},
    "opendoor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouverture de l'accès"])}
  },
  "apartmentsList": {
    "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Appartements, maisons, villas (", _interpolate(_named("count")), ")"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un appartement"])},
    "arrayColumns": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "firstResident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Première fiche"])},
      "residents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résident(s)"])},
      "badges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Badge(s)"])}
    },
    "noAuthorizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas l'autorisation de consulter les appartements."])}
  },
  "intercomsList": {
    "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Affiché sur les platines (", _interpolate(_named("count")), ")"])},
    "arrayColumns": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])}
    }
  },
  "codesList": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les codes par accès"])},
    "count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " accès"])},
    "keypadsCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codes clavier"])},
    "phoneCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codes téléphoniques"])}
  },
  "centralUnitsList": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centrales"])},
    "listing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " centrale"]), _normalize([_interpolate(_named("count")), " centrale"]), _normalize([_interpolate(_named("count")), " centrales (", _interpolate(_named("first")), " à ", _interpolate(_named("last")), ")"])])},
    "realTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps réél"])},
    "readWrite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecture / écriture"])},
    "lora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compatible Connect-It"])},
    "searchCentral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher une centrale"])},
    "arrayColumns": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "serial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° de série"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° de téléphone"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "updateStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut de la mise à jour"])},
      "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])}
    }
  },
  "keyCard": {
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un badge"])},
    "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Badge"])},
    "teleco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécommande"])},
    "serial": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["n°", _interpolate(_named("serial"))])},
    "delete": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Voulez-vous vraiment supprimer le badge ", _interpolate(_named("serial")), " ?"])},
    "mobil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clémobil"])},
    "kibolt": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clé Kibolt"])},
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette clé doit être chargée"])},
      "lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette clé est déclarée comme perdue"])}
    },
    "copie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Badge copié"])},
    "colors": {
      "black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noir"])},
      "beige": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beige"])},
      "green": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vert"])},
      "blue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bleu"])},
      "red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rouge"])},
      "brown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marron"])},
      "grey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gris"])},
      "yellow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaune"])},
      "orange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orange"])},
      "purple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Violet"])},
      "white": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blanc"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non spécifiée"])}
    }
  },
  "residenceCard": {
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une résidence"])}
  },
  "residentCard": {
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une fiche"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant"])},
    "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "mainNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro principal"])},
    "sideNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro secondaire"])},
    "delete": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Voulez-vous vraiment supprimer le résident ", _interpolate(_named("nom")), " ?"])}
  },
  "stairwellCard": {
    "createStairwell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une cage"])},
    "createBuilding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un bâtiment"])},
    "accesses": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Accès (", _interpolate(_named("count")), ")"])},
    "stairwells": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " cage"]), _normalize([_interpolate(_named("count")), " cages"])])},
    "apartments": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " appartement"]), _normalize([_interpolate(_named("count")), " appartements"])])}
  },
  "subaccountsList": {
    "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sous-comptes (", _interpolate(_named("count")), ")"])},
    "arrayColumns": {
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "fisrtName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])}
    }
  },
  "agencesList": {
    "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Agences (", _interpolate(_named("count")), ")"])},
    "arrayColumns": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "adress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])}
    }
  },
  "text": {
    "userDataLaws": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous disposez d'un droit d'accès, de modification, de rectifiction et de suppression des données qui vous concernent (art. 34 de la loi Informatique et Libertés). Pour l'exercer, adressez vous à"])},
    "contactEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email : "])},
    "emailIntratoneSuffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["intratone.fr"])},
    "linkEmailIdentity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["technique"])},
    "linkEmailDomain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["intratone.fr"])},
    "contactAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse : ZI de Maunit - 370 rue de Maunit"])},
    "contactCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["85290 MORTAGNE SUR SEVRE"])},
    "contactPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone : 02 51 65 51 84"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])}
  },
  "brands": {
    "windows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Windows"])},
    "apple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apple"])}
  },
  "bloc_erp": {
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
    "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à"])},
    "nom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
    "visio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appel visio"])},
    "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse email"])},
    "appPC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nécessaire pour appel vidéo sur PC"])},
    "telsec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone Secondaire"])},
    "visiosec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appel visio sur téléphone secondaire"])},
    "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couleur"])}
  },
  "redirect": {
    "https": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirection to the site in HTTPS"])},
    "http": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirection to the site in HTTP"])}
  },
  "MCI": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon Compte Intratone"])},
    "select": {
      "gestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je suis gestionnaire"])},
      "resident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je suis résident"])}
    },
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour accéder à votre compte résident, cliquez sur le bouton ci-dessous."])}
  },
  "module": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modules à remplacer"])},
    "excelExport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter en Excel"])},
    "replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remplacer"])},
    "table": {
      "centraleName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centrale"])},
      "serialModule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° de série du module"])},
      "ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence de remplacement"])},
      "portaconnectCompatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence compatible Portaconnect"])},
      "replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplacer le module"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher une résidence ou un N° de série de module"])},
      "portaconnectInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S’il s’agit d’un portail, vous pouvez bénéficier de l’offre Portaconnect. Découvrez les détails en cliquant <a href=\"https://portaconnect.intratone.fr/\">ici</a>."])},
      "finishDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'arrêt"])},
      "PREPAID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin du prépayé"])},
      "2G": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin 2G"])}
    },
    "toReplace": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau module"])},
      "replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Module à remplacer"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° de téléphone actuel"])},
      "centrale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la centrale"])}
    },
    "replacement": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplacement de module"])},
      "newPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau n° de téléphone"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger les CléMobil"])}
    },
    "error": {
      "notCompatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce module n’est pas compatible pour remplacer le matériel en place"])},
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention !"])},
      "explication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour valider le remplacement, veuillez télécharger la liste des CléMobil concernées par le changement de numéro de téléphone afin de prévenir les résidents concernés."])},
      "isNotFinish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous quittez la page sans terminer le remplacement, celui-ci ne sera pas pris en compte. Souhaitez-vous quitter la page ?"])},
      "noDataAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des modules à remplacer vide."])},
      "noDataSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune donnée ne correspond à votre recherche."])}
    },
    "success": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le remplacement du module ", _interpolate(_named("oldName")), " par le module ", _interpolate(_named("newName")), " est pris en compte."])},
      "explication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce module sera mis à jour dès qu’il sera sous tension. Le remplacement dans le site de gestion sera fait à ce moment-là."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminer le remplacement"])}
    },
    "infos": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour le maintien des fonctionnalités de votre installation, votre module doit être remplacé. Ce remplacement s’impose pour l’une des raisons suivantes."])},
      "endedContract": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrat à échéance"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est possible que votre contrat de services, basé sur 10 ans de communications prépayées, arrive à son terme. Dans ce cas, il est recommandé de procéder au renouvellement du matériel."])}
      },
      "ended2G": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin du réseau 2G"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selon les différents opérateurs, la date d'arrêt du réseau 2G peut varier. Actuellement, il est prévu que l'intégralité du réseau 2G disparaisse fin 2025 au plus tôt et d'ici fin 2026 au plus tard. Cette évolution technologique, indépendante de notre contrôle, impliquera la perte de certaines fonctionnalités.  Il est recommandé de procéder au renouvellement du matériel."])},
        "changeCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour visualiser, les dates d'arrêt des autres pays, veuillez changer le pays en haut de la page."])},
        "languageVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version française"])}
      }
    }
  },
  "date": {
    "january": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " janvier ", _interpolate(_named("year"))])},
    "february": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " fevrier ", _interpolate(_named("year"))])},
    "march": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " mars ", _interpolate(_named("year"))])},
    "april": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " avril ", _interpolate(_named("year"))])},
    "may": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " mai ", _interpolate(_named("year"))])},
    "june": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " juin ", _interpolate(_named("year"))])},
    "july": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " juillet ", _interpolate(_named("year"))])},
    "august": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " août ", _interpolate(_named("year"))])},
    "september": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " septembre ", _interpolate(_named("year"))])},
    "october": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " octobre ", _interpolate(_named("year"))])},
    "november": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " novembre ", _interpolate(_named("year"))])},
    "december": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("day")), " décembre ", _interpolate(_named("year"))])}
  },
  "kibolt": {
    "connected_key_programmer_but_nothing_happens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez branché votre clé au programmateur, mais rien ne se passe ?"])},
    "connected_cylinder_programmer_but_nothing_happen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez branché votre cylindre au programmateur, mais rien ne se passe ?"])},
    "plug_in_programmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branchez votre programmateur"])},
    "key_is_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["la clé est supprimée"])},
    "key_is_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["la clé est à jour"])},
    "delete_failed_restart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Echec de suppression. Recommencer."])},
    "update_failed_restart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Echec de mise à jour. Recommencer."])},
    "currently_being_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours de suppression"])},
    "currently_updating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours de mise à jour"])},
    "then_wait_few_seconds_be_detected_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["...puis attendez quelques secondes qu'elle soit détectée par l'application."])},
    "plug_then_wait_seconds_to_detected_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branchez le, puis attendez quelques secondes qu'il soit détecté par l'application."])},
    "your_programmer_not_responding_even_though_it_is_plugged_in?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre programmateur ne répond pas alors qu'il est branché ?"])},
    "replace_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplacer le cylindre"])},
    "move_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déplacer le cylindre"])},
    "remove_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le cylindre"])},
    "size_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille du cylindre"])},
    "cylinder_detected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cylindre détecté"])},
    "insert_replacement_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insérez-y le cylindre de remplacement"])},
    "insert_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insérez-y le cylindre"])},
    "insert_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insérez-y la clé"])},
    "wait_seconds_detected_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["...puis attendez quelques secondes qu'il soit détecté par l'application."])},
    "keys_still_open_until_updated_or_barrel_is_re_declared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les clés peuvent toujours ouvrir jusqu'à ce qu'elles soient mises à jour ou que le canon soit redéclaré"])},
    "unplug_kiprog_duration_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne débranchez pas le Kiprog pendant toute la durée de la mise à jour."])},
    "replacement_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplacement en cours."])},
    "can_remove_kiprog_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez retirer le Kiprog du cylindre."])},
    "replacement_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplacement terminées."])},
    "be_careful_cylinder_not_functional_try_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention, le cylindre n'est pas fonctionnel. Vous devez réessayer."])},
    "updates_completed_remove_kiprog_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour terminée. Vous pouvez retirer le Kiprog du cylindre."])},
    "update_progress_not_unplug_Kiprog_duration_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour en cours. Ne débranchez pas le Kiprog pendant toute la durée de la mise à jour."])},
    "requires_administrator_rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nécessite des droits d'administrateur"])},
    "remote_control_and_badge_programmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmateur de télécommandes et badges"])},
    "kibolt_key_programmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmateur de clé Kibolt"])},
    "unknown_error_contact_customer_or_try_later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur inconnue est survenue. Veuillez contacter le service client ou réessayer ultérieurement."])},
    "error_occurred_during_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite pendant la mise à jours."])},
    "cylinder_already_exists_insert_another_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le cylindre présent dans le programmateur n'existe pas ou est déjà utilisé. Veuillez insérer un autre cylindre."])},
    "cylinder_not_correspond_cylinder_updated_insert_correct_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le cylindre présent dans le programmateur ne correspond pas au cylindre à mettre à jours. Veuillez insérer le bon cylindre."])},
    "you_need_update_programmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez mettre à jour la version de votre programmateur pour pouvoir ajouter ou mettre à jour des accessoires."])},
    "name_door": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la porte"])},
    "type_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de cylindre"])},
    "version_soft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version soft"])},
    "version_boot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version boot"])},
    "this_is_name_door": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceci est le nom de la porte"])},
    "kibolt_cylinder_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jours du cylindre Kibolt"])},
    "kibolt_cylinder_replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplacement du cylindre Kibolt"])},
    "simple_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canon de porte de type simple"])},
    "double_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canon de porte de type double"])},
    "button_cylinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canon de porte de type à bouton"])}
  },
  "TO_UPDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour"])},
  "INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["informations"])},
  "TECHNICAL_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails techniques"])},
  "TRY_AGAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réessayer"])},
  "CLICK_HERE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez ici"])},
  "REPLACEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplacement"])},
  "UPDATING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour"])},
  "CONNECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecté"])},
  "NOT_CONNECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas connecté"])},
  "INSTALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installer"])},
  "SERIAL_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de série"])},
  "VERSION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de version"])},
  "END": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminer"])},
  "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "COMMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
  "SHOW_MORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher plus"])},
  "SERIAL_NUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° de série"])},
  "ADVANCED_OPTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options avancées"])},
  "MOVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déplacer vers"])},
  "KIBOLT_ACCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès Kibolt"])}
}