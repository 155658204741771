<template>
    <component :is="layout">
        <slot></slot>
    </component>
    <!-- V1 variabales -->
    <input id="intratone_autoauth" :value="checkRight('G_AUTOS')" hidden />
    <input id="intratone_locale" :value="$i18n.locale" hidden />
    <input id="intratone_v1_url" :value="intratoneV1URL" hidden />
    <!-- TEMP -->
    <input
        id="intratone_sid"
        :value="$store.getters['auth/getIntratoneDev2SID']"
        hidden
    />
</template>

<script>
import PublicLayout from "@/layout/PublicLayout.vue"
import PrivateLayout from "@/layout/PrivateLayout.vue"
import InfosLayout from "@/layout/InfosLayout.vue"
import { getAPIDataURL } from "@/services/httpClient.js"

export default {
    components: {
        PublicLayout,
        PrivateLayout,
        InfosLayout,
    },
    data() {
        return {
            layout: null,
            API_DATA_BASE: window.location.pathname,
            API_DATA_URL: window.location.origin,
            intratoneV1URL:
                window.location.origin + window.location.pathname + "/",
            apiintra_url: getAPIDataURL(),
        }
    },
    watch: {
        $route(to) {
            if (to.meta.layout !== undefined) {
                this.layout = to.meta.layout
            } else {
                // this is default layout if route meta is not set
                this.layout = "PrivateLayout"
            }

            // Changing language according to URL
            const language = this.$store.getters["account/getLanguage"]
            const urlLang = window.location.pathname.replaceAll("/", "")
            if (language !== urlLang) {
                this.$store.commit("account/setLanguage", urlLang)
            }
            localStorage.setItem("apiintra_url", this.apiintra_url)
            window.scrollTo(0, 0)
        },
    },
    created() {
        // changing V1 Url according to environment
        this.API_DATA_BASE =
            process.env.VUE_APP_PHP_SITE_BASE != ""
                ? process.env.VUE_APP_PHP_SITE_BASE
                : window.location.pathname
        this.API_DATA_URL =
            process.env.VUE_APP_PHP_SITE_URL != ""
                ? process.env.VUE_APP_PHP_SITE_URL
                : window.location.origin
        this.intratoneV1URL = this.API_DATA_URL + this.API_DATA_BASE + "/"
    },
    mounted() {
        localStorage.setItem("apiintra_url", this.apiintra_url)
    },
}
</script>

<style lang="scss">
@import "@/assets/scss/global.scss";
@import "@/assets/scss/variables/fontSize.scss";
@import "@/assets/scss/v1-scss/jquery-ui.scss";

#app {
    background-color: $blue-neutral;
    font-family: "Avenir Roman";
    color: $blue-neutral;
    // letter-spacing: 0.05em;
    font-size: $normal;

    @media all and (max-width: 1024px) {
        background-color: transparent;
    }
}

body {
    overflow: hidden;
}

.Vue-Toastification__container {
    cursor: pointer;
}

.Vue-Toastification__container .customToast {
    top: 12vh;
}

@media print {
    html .Vue-Toastification__container,
    html .inbenta-bot__launcher {
        display: none !important;
    }
}
</style>
