import dayjs from "dayjs"
import { createRouter, createWebHashHistory } from "vue-router"
import global from "./global"
import vigik from "./vigik"
import residence from "./residence"
import auth from "./auth"
import v1 from "./v1"
import store from "@/store"
import infos from "./infos"

import i18n from "@/i18n"

import { useToast } from "vue-toastification"
const toast = useToast()

const notFoundHandler = {
    path: "/:pathMatch(.*)*",
    redirect: "/error/404",
}
const errorHandler = {
    path: "/error/:code",
    name: "Error",
    component: () => import("@/views/home/Error.vue"),
}

window.popStateDetected = false
window.addEventListener("popstate", () => {
    window.popStateDetected = true
})

/**
 * Guard the route from unauthorized users.
 *
 * @param  {Route}    to   The route we want to access.
 * @param  {Route}    from The route from which we are coming from.
 * @param  {Function} next Callback for passing a route to be called next.
 * @return {void}
 */
function guardRoute(to, from, next) {
    // work-around to get to the Vuex store (as of Vue 2.0)
    const auth = store.state.auth
    const account = store.state.account

    // check if token is valid
    const tokenIsValidFlag = tokenIsValid(auth.renewExpireAt)

    // if user is not logged -> redirection
    if (
        (!auth.logged || !tokenIsValidFlag) &&
        to.path !== "/logout" &&
        to.meta?.layout !== "PublicLayout" &&
        to.meta?.layout !== "InfosLayout"
    ) {
        toast.info(i18n.global.t("alerts.connection.expired"), {
            icon: "fas fa-power-off",
        })
        next({
            path: "/logout",
            query: {
                call: 0,
            },
        })
        return
    }

    // if user is not admin and does not have permission to route -> redirection
    if (!account.isAdmin && to.meta.authorize && to.meta.authorize.length > 0) {
        let isAuthorized = false
        for (const right of auth.rights) {
            if (to.meta.authorize.includes(right)) {
                isAuthorized = true
                break
            }
        }
        if (!isAuthorized) {
            next({
                path: "error/401",
            })
            return
        }
    }

    // if user is admin or not, and he doesn't have privilege -> redirection
    if (auth.logged && to.meta.privileges && to.meta.privileges.length > 0) {
        let hasPrivilege = false
        for (const privilege of auth.privileges) {
            if (to.meta.privileges.includes(privilege)) {
                hasPrivilege = true
                break
            }
        }
        if (!hasPrivilege) {
            next({
                path: "error/403",
            })
            return
        }
    }

    if (to.meta.tab !== undefined) {
        localStorage.setItem("tab", to.meta.tab)
    }

    if (to.path == "/renew") {
        to.query["fromRoute"] = from.fullPath
    }
    next()
}

function updateStore(to, from, next) {
    if (localStorage.getItem("vuex") != null) {
        let vuex = JSON.parse(localStorage.getItem("vuex"))
        if (vuex.auth != null && vuex.account != null) {
            let auth = vuex.auth
            let account = vuex.account
            if (
                auth.jwt != store.state.auth.jwt &&
                account.login != store.state.account.login
            ) {
                store.commit("auth/setState", auth)
                store.commit("account/setState", account)
            }
        }
    }

    next()
}

function tokenIsValid(tokenExpireAt) {
    // if expireAt is taller that current date, the token has expired
    let utc = require("dayjs/plugin/utc")
    dayjs.extend(utc)
    return dayjs.utc(tokenExpireAt) > dayjs.utc()
}

const routes = [
    notFoundHandler,
    errorHandler,
    ...global,
    ...vigik,
    ...residence,
    ...auth,
    ...v1,
    ...infos,
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
})

router.beforeEach(guardRoute)
router.beforeEach(updateStore)

import Vue from "vue"

const DEFAULT_TITLE = "Intratone"
router.afterEach((to, from) => {
    document.title = to.meta.title || DEFAULT_TITLE
})

export default router
